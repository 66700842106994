import { render, staticRenderFns } from "./MissingTranslation.vue?vue&type=template&id=c8e0f6a2&"
import script from "./MissingTranslation.vue?vue&type=script&lang=js&"
export * from "./MissingTranslation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/Users/allanjones/Code/deakin/muse/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c8e0f6a2')) {
      api.createRecord('c8e0f6a2', component.options)
    } else {
      api.reload('c8e0f6a2', component.options)
    }
    module.hot.accept("./MissingTranslation.vue?vue&type=template&id=c8e0f6a2&", function () {
      api.rerender('c8e0f6a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Common/MissingTranslation.vue"
export default component.exports