var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "fill-height", attrs: { justify: "center", align: "end" } },
    [
      _c("div", { staticClass: "pb-6" }, [
        _vm._v(" " + _vm._s(_vm.leftLabel) + " ")
      ]),
      _c(
        "div",
        { staticClass: "mx-2" },
        [
          _c("vertical-bar-chart", {
            attrs: { data: _vm.chartData, config: _vm.chartConfig }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column justify-space-between fill-height py-6"
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c(
              "div",
              { staticClass: "text-body-1 grey--text text--darken-2" },
              [_vm._v(" Average ")]
            ),
            _c(
              "div",
              {
                staticClass: "text-h6 black--text text-no-wrap font-weight-bold"
              },
              [_vm._v(" " + _vm._s(_vm.questionAverage) + " ")]
            )
          ]),
          _vm._v(" " + _vm._s(_vm.rightLabel) + " ")
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }