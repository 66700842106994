var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c(
        "v-card-text",
        { staticClass: "py-0 font-weight-bold" },
        _vm._l(_vm.rankedRegions, function(rankedRegion) {
          return _c(
            "div",
            {
              key: rankedRegion.region,
              staticClass: "d-flex text-subtitle-1 black--text mb-1"
            },
            [
              _c("div", { staticClass: "mr-5 font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.extractRegionKey(rankedRegion.region)) +
                    "xx "
                )
              ]),
              _c("div", { staticClass: "flex-grow-1" }, [
                _vm._v(
                  " " + _vm._s(_vm.postcodeRegion(rankedRegion.region)) + " "
                )
              ]),
              _c("div", { staticClass: "grey--text text--darken-1" }, [
                _vm._v(
                  " " +
                    _vm._s(rankedRegion.count) +
                    " (" +
                    _vm._s(rankedRegion.percentage.toFixed(1)) +
                    "%) "
                )
              ])
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }