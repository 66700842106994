var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "header-image" } }, [
    _c(
      "div",
      { key: _vm.imageId, staticClass: "d-flex flex-nowrap" },
      [
        _vm.blob
          ? _c(
              "v-img",
              {
                staticClass: "white--text align-end pa-4",
                attrs: {
                  height: "120px",
                  gradient: "rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)",
                  src: _vm.blob
                }
              },
              [_vm._t("default")],
              2
            )
          : _c(
              "div",
              {
                staticClass: "grey lighten-1 align-end image-placeholder pa-4"
              },
              [_vm._t("default")],
              2
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }