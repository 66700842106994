var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("page-header-bar", [
        _c("div", { staticClass: "d-flex p-relative" }, [
          _c("div", { staticClass: "flex-grow-1 d-flex flex-column" }, [
            _c("h2", { staticClass: "white--text font-weight-medium" }, [
              _vm._v(" " + _vm._s(_vm.title) + " ")
            ])
          ])
        ])
      ]),
      _c(
        "v-container",
        { attrs: { "d-flex": "", "justify-center": "", "pa-2": "" } },
        [
          _c("div", { staticClass: "d-flex flex-column justify-center" }, [
            _c("div", { staticClass: "pa-8" }, [
              _vm._v(" " + _vm._s(_vm.message) + " ")
            ]),
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-btn",
                  { attrs: { color: "primary" }, on: { click: _vm.home } },
                  [_vm._v(" " + _vm._s(_vm.button) + " ")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }