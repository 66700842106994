var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-word-cloud", {
        style: _vm.visualisationSize,
        attrs: {
          words: _vm.wordCountPairs,
          color: _vm.weight,
          "font-family": "museo-sans",
          rotation: _vm.wordRotation,
          "rotation-unit": "turn",
          spacing: 1,
          "font-size-ratio": 4,
          "animation-duration": 0
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }