<template>
  <div v-if="dropzoneConfig">
    <vue-dropzone
      :config="dropzoneConfig"
      :existing-file-url="imageSrc"
      @added-file="handleAddedFile"
      @sending="handleSending"
      @success="handleSuccessfulUpload"
    >
      <v-card v-bind="cardProps" class="dashed clickable">
        <v-card-text class="pa-0 fill-height">
          <div
            v-if="hasNoImage"
            class="fill-height d-flex justify-space-around align-center"
          >
            <v-btn color="primary" dark fab>
              <v-icon>add</v-icon>
            </v-btn>
          </div>
          <div v-else class="image-preview">
            <v-btn
              v-if="imageId && !disable"
              color="error"
              x-small
              dark
              fab
              absolute
              right
              @click.stop="deleteImage"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-img ref="vImage" aspect-ratio="1.33" :src="imageSrc">
              <template v-slot:placeholder>
                <v-row
                  class="grey lighten-2 fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
        </v-card-text>
      </v-card>
    </vue-dropzone>
    <span
      class="text-caption"
      :class="[error ? 'red--text' : 'grey--text']"
    >
      Images must be 2048px x 1536px
    </span>
  </div>
</template>

<script>
import VueDropzone from './VueDropzone';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ImageUploadCard',
  components: { VueDropzone },
  props: {
    imageId: {
      type: String,
      required: false,
      default: null
    },
    organisationId: {
      type: String,
      required: true
    },
    imageType: {
      type: String,
      required: true
    },
    disable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      imageSrc: '',
      imageFile: null,
      dropzoneConfig: null,
      error: false
    };
  },
  computed: {
    ...mapState({
      survey: state => state.survey.survey,
      dropzoneBaseConfig: state =>
        state.config.config.dropzoneBaseConfig
    }),
    hasNoImage() {
      return !this.imageId && !this.imageFile;
    },
    hasImage() {
      return !this.hasNoImage;
    },
    cardProps() {
      return this.hasNoImage ? { outlined: true } : { flat: true };
    }
  },
  async created() {
    let vm = this;

    this.dropzoneConfig = {
      ...this.dropzoneBaseConfig,
      params() {
        return {
          organisationId: vm.organisationId,
          imageType: vm.imageType
        };
      },
      accept: async function(file, done) {
        const img = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => resolve(img);
          };
          reader.onerror = error => reject(error);
        });
        if (img.width == 2048 && img.height == 1536) {
          if (vm.error) vm.error = false;
          done();
        } else {
          vm.error = true;
          vm.imageFile = null;
        }
      }
    };

    if (this.imageId) {
      this.getImage(this.imageId).then(
        res => (this.imageSrc = res.data.url)
      );
    }
  },
  methods: {
    ...mapActions('organisation', ['getImage']),
    handleAddedFile(file) {
      this.imageFile = file;
    },
    handleSending() {
      this.$refs.vImage.isLoading = true;
    },
    handleSuccessfulUpload({ file, response }) {
      this.imageSrc = file.dataURL;
      this.$emit('image-uploaded', response);
    },
    deleteImage() {
      this.imageFile = null;
      this.imageSrc = '';
      this.$emit('delete-image');
    }
  }
};
</script>

<style scoped lang="scss">
.v-card {
  width: 341px;
  height: 256px;
}
.dashed.v-card.v-card--outlined {
  border-style: dashed;
}
.image-preview {
  .v-image {
    border-radius: 4px 4px 0 0;
  }
  .v-btn {
    transform: translate(75%, -25%);
  }
}
</style>
