var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header-bar",
        [
          _c(
            "div",
            { staticClass: "fab-container p-absolute" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "floating-button ml-n13",
                  attrs: {
                    color: "grey lighten-2 black--text",
                    dark: "",
                    small: "",
                    fab: ""
                  },
                  on: { click: _vm.backToOrganisations }
                },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-h5 white--text font-weight-medium" },
                [_vm._v(" " + _vm._s(_vm.organisation.name) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-8" },
        [
          !_vm.loaded
            ? _c("organisation-settings-loader")
            : _c("organisation-settings", {
                attrs: { organisation: _vm.organisation }
              })
        ],
        1
      ),
      _c("organisation-dialog", {
        attrs: { organisation: _vm.organisation, show: _vm.organisationDialog },
        on: {
          "update:show": function($event) {
            _vm.organisationDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }