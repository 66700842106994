var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-snackbar",
        {
          staticClass: "pb-4",
          attrs: {
            color: "primary",
            timeout: "-1",
            "multi-line": "",
            vertical: ""
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.enableLiveSurveyData()
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Reload surveys ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.liveDataPaused,
            callback: function($$v) {
              _vm.liveDataPaused = $$v
            },
            expression: "liveDataPaused"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "pa-0 d-flex" },
            [
              _c("v-icon", { staticClass: "mr-3 mb-2" }, [
                _vm._v("offline_bolt")
              ]),
              _vm._v(
                " You've been inactive for over 15 minutes, so the data on this page may not be up to date. Would you like to reload your surveys now? "
              )
            ],
            1
          )
        ],
        1
      ),
      _c("survey-grid", {
        attrs: {
          surveys: _vm.surveys,
          organisations: _vm.organisations,
          loading: !_vm.loaded
        },
        on: {
          "survey-action-request": _vm.handleSurveyActionRequest,
          "add-survey-card-click": _vm.showSurveyDialogForNewSurvey
        }
      }),
      _vm.showSurveyDialog
        ? _c("survey-dialog", {
            attrs: {
              show: _vm.showDialog,
              survey: _vm.surveyInDialog,
              organisations: _vm.organisations
            },
            on: {
              "update:show": function($event) {
                _vm.showDialog = $event
              },
              "update:survey": function($event) {
                _vm.surveyInDialog = $event
              },
              save: _vm.handleSurveySaved
            }
          })
        : _vm.showSurveyActionDialog
        ? _c("survey-action-dialog", {
            attrs: {
              show: _vm.showDialog,
              survey: _vm.surveyInDialog,
              action: _vm.surveyAction
            },
            on: {
              "update:show": function($event) {
                _vm.showDialog = $event
              },
              "update:survey": function($event) {
                _vm.surveyInDialog = $event
              },
              "dialog-action-confirmed": _vm.handleDialogActionConfirmed
            }
          })
        : _vm.showSurveyDuplicateDialog
        ? _c("survey-duplicate-dialog", {
            attrs: {
              show: _vm.showDialog,
              survey: _vm.surveyInDialog,
              organisations: _vm.organisations
            },
            on: {
              "update:show": function($event) {
                _vm.showDialog = $event
              },
              "update:survey": function($event) {
                _vm.surveyInDialog = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }