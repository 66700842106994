var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            _vm._l(_vm.languages, function(language, index) {
              return _c(
                "v-col",
                {
                  key: index,
                  staticClass: "pt-0 d-flex flex-column align-center"
                },
                [
                  _c(
                    "v-avatar",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        color: _vm.colorForLanguage(language),
                        size: "100px"
                      }
                    },
                    [
                      _c("span", { staticClass: "white--text text-h5" }, [
                        _vm._v(_vm._s(language))
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "d-block text-center" }, [
                    _c("div", { staticClass: "text-h6 black--text" }, [
                      _vm._v(
                        " " + _vm._s(_vm.languageResponses(language)) + " "
                      )
                    ]),
                    _c("div", { staticClass: "text-body-1" }, [
                      _vm._v(
                        " (" + _vm._s(_vm.responsePercentage(language)) + "%) "
                      )
                    ])
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }