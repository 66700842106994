var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "primary-language-select" },
        [
          _c("language-select", {
            attrs: {
              value: _vm.primaryLanguage,
              languages: _vm.languages,
              "prepend-icon": "language",
              label: "Primary Language",
              "presistent-hint": "",
              hint: "Required *",
              "show-helper": "",
              rules: _vm.validationRules.primaryLanguage
            },
            on: { change: _vm.onUpdatePrimaryLanguage },
            scopedSlots: _vm._u([
              {
                key: "tooltip",
                fn: function() {
                  return [
                    _vm._v(
                      " Primary Language is the language used to build your survey and will be used as the basis for translating secondary languages "
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c("language-select", {
        attrs: {
          value: _vm.secondaryLanguages,
          languages: _vm.secondaryLanguageOptions,
          multiple: "",
          autocomplete: "",
          "prepend-icon": "translate",
          label: "Secondary Languages",
          "menu-props": { offsetY: true }
        },
        on: { change: _vm.onUpdateSecondaryLanguage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }