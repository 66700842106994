var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    _vm._l(_vm.circles, function(circle, index) {
      return _c(
        "v-col",
        {
          key: index,
          staticClass: "d-flex justify-center py-0",
          attrs: { cols: "12", md: "6" }
        },
        [
          _c(
            "v-card",
            { staticClass: "text-center", attrs: { flat: "", height: "100%" } },
            [
              _c(
                "v-card-text",
                { staticClass: "py-0" },
                [
                  _c("circle-svg", {
                    attrs: {
                      size: 200,
                      scale: circle.average,
                      colors: _vm.circleColor(circle)
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-h6 black--text font-weight-bold" },
                    [
                      _c("localised-text", {
                        attrs: {
                          language: _vm.displayLanguage,
                          string: circle.label
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v(
                      " " + _vm._s(_vm.formatPercent(circle.average)) + " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }