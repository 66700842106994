var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-8 p-relative" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-row", [
                _c(
                  "div",
                  { staticClass: "text-subtitle-1 font-weight-bold mb-4" },
                  [_vm._v(" Show responses from: ")]
                )
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "startDateMenu",
                          attrs: {
                            "close-on-content-click": false,
                            "return-value": _vm.startDate,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "290px"
                          },
                          on: {
                            "update:returnValue": function($event) {
                              _vm.startDate = $event
                            },
                            "update:return-value": function($event) {
                              _vm.startDate = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "date-field mt-n2 mr-2",
                                          attrs: {
                                            value: _vm.formattedPickerDate(
                                              _vm.startDate
                                            ),
                                            label: "Start date",
                                            "prepend-icon": "event",
                                            readonly: ""
                                          }
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.startDateMenu,
                            callback: function($$v) {
                              _vm.startDateMenu = $$v
                            },
                            expression: "startDateMenu"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { "no-title": "", scrollable: "" },
                            on: { change: _vm.handleChangeStartDate },
                            model: {
                              value: _vm.startDate,
                              callback: function($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "endDateMenu",
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "290px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "date-field mt-n2",
                                          attrs: {
                                            value: _vm.formattedPickerDate(
                                              _vm.endDate
                                            ),
                                            label: "End date",
                                            "prepend-icon": "event",
                                            readonly: ""
                                          }
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.endDateMenu,
                            callback: function($$v) {
                              _vm.endDateMenu = $$v
                            },
                            expression: "endDateMenu"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { "no-title": "", scrollable: "" },
                            on: { change: _vm.handleChangeEndDate },
                            model: {
                              value: _vm.endDate,
                              callback: function($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-row", [
                _c(
                  "div",
                  { staticClass: "text-subtitle-1 font-weight-bold mb-6" },
                  [_vm._v(" Language for statistics ")]
                )
              ]),
              _c(
                "v-row",
                [
                  _c("survey-language-status", {
                    attrs: {
                      "language-status": _vm.translationStatus,
                      highlight: _vm.selectedLanguage
                    },
                    on: { "language-clicked": _vm.changeSelectedLanguage }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c(
        "v-row",
        _vm._l(_vm.questionStats, function(questionStat, index) {
          return _c(
            "v-col",
            {
              key: index,
              staticClass: "d-flex flex-column",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c(
                "dashboard-item",
                _vm._b(
                  {
                    staticClass: "flex-grow-1",
                    attrs: {
                      "total-respondents": _vm.surveyResponseCount,
                      "display-language": _vm.selectedLanguage
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function() {
                            return [
                              _c(
                                _vm.visualisationComponentForStat(questionStat),
                                _vm._b(
                                  {
                                    tag: "component",
                                    attrs: {
                                      "display-language": _vm.selectedLanguage
                                    }
                                  },
                                  "component",
                                  { questionStat: questionStat },
                                  false
                                )
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "dashboard-item",
                  { questionStat: questionStat },
                  false
                )
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }