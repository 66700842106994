var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("slider-svg", {
        staticClass: "mx-12",
        attrs: {
          theme: _vm.theme,
          width: _vm.responsiveWidth,
          "slider-value": _vm.questionStat.average,
          "middle-tick": !!_vm.questionStat.middleWord
        }
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between" },
        _vm._l(_vm.sliderPoints, function(sliderPoint, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "text-body-1 text-center font-weight-bold label"
            },
            [
              _c("localised-text", {
                attrs: {
                  language: _vm.displayLanguage,
                  string: sliderPoint.text
                }
              })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }