var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    { staticClass: "primary" },
    [_c("v-container", { staticClass: "py-8 px-12" }, [_vm._t("default")], 2)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }