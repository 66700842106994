var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.getRandomFeedback, function(feedback) {
        return _c(
          "div",
          {
            key: feedback,
            staticClass: "comment-container mb-2 font-italic text-justify"
          },
          [_vm._v(" " + _vm._s(feedback) + " ")]
        )
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mt-3 show-responses-button",
              attrs: { color: "primary" },
              on: { click: _vm.showAllResponsesDialog }
            },
            [_vm._v(" Show all responses ")]
          ),
          _vm.showDialogRequested
            ? _c("free-text-responses-modal", {
                attrs: {
                  "question-stat": _vm.questionStat,
                  show: _vm.showDialogRequested,
                  "display-language": _vm.displayLanguage
                },
                on: { cancel: _vm.cancelAllResponsesDialog }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }