var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.questions, function(question, i) {
      return _c("duplicate-question-list-item", {
        key: question.id,
        attrs: {
          index: i + 1,
          title: _vm.getTitle(question.titleMap),
          type: _vm.getType(question.type),
          duplicate: question.duplicate
        },
        on: {
          "toggle-duplication": function($event) {
            return _vm.$emit("toggle-duplication", question)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }