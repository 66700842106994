var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "", height: "200" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-end top" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", dark: "", fab: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("show-survey-dialog")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("add")])],
                1
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-start bottom" },
            [
              _c("v-spacer"),
              _c("p", { staticClass: "text-h5" }, [_vm._v("Create Survey")]),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }