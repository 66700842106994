<template>
  <v-card class="pa-4">
    <v-row no-gutters class="flex-nowrap">
      <v-col :class="[questionIndexStyles, 'mr-5 mt-3']" cols="auto">
        {{ index }}.
      </v-col>
      <v-col>
        <v-form ref="form">
          <v-row class="mb-2" no-gutters justify="space-between">
            <v-col cols="8">
              <v-text-field
                v-model="question.text.languageMap[targetLanguage]"
                class="text-h6 pt-0"
                :error="textHasError"
                hide-details
                autofocus
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <question-form-action-buttons
                :question-has-responses="questionHasResponses"
                @delete="handleDeleteQuestion"
                @clear-responses="handleDeleteQuestionResponses"
                @duplicate="handleDuplicateQuestion"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <question-type-selector
                v-model="question.config.questionType"
                v-bind="selectorProps(questionProperty.type)"
                @show-question-type-selector-dialog="
                  showTypeSelectorDialog
                "
                @change="selectedNewType"
              />
            </v-col>
            <v-col cols="4"
              ><question-theme-selector
                v-model="question.theme"
                v-bind="selectorProps(questionProperty.theme)"
            /></v-col>
          </v-row>

          <div v-if="question.hasAdditionalConfig()">
            <component
              :is="questionConfigComponent"
              v-model="question.config"
              :question-has-responses="questionHasResponses"
              :target-language="targetLanguage"
            />
          </div>
        </v-form>
        <div
          :class="[
            formHasErrors || questionHasResponses
              ? 'justify-space-between mt-6'
              : 'justify-end',
            'd-flex'
          ]"
        >
          <div
            v-if="questionHasResponses"
            class="d-flex align-center"
          >
            <div>
              This question has
              <span class="font-weight-bold text-body-2">{{
                responseCountText
              }}</span>
              from surveys and cannot be edited
              <v-icon
                class="clickable"
                color="primary"
                @click="showNoEditDialog = true"
                >help</v-icon
              >
            </div>
          </div>
          <div
            v-else-if="!isComplete && formHasErrors"
            class="d-flex align-center"
          >
            <v-icon class="mr-4" color="red">error</v-icon>
            Question is missing required details
          </div>
          <v-btn
            v-shortkey="['esc']"
            small
            @click="close"
            @shortkey="close"
          >
            Close
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <question-type-selector-dialog
      v-if="showQuestionTypeSelectorDialog"
      :pre-dialog-selected-type="question.type()"
      :show.sync="showQuestionTypeSelectorDialog"
      @select-new-question-type="selectedNewType"
    />
    <component
      :is="confirmationDialogComponent"
      v-if="showActionConfirmationDialog"
      :question-title="question.text.languageMap[targetLanguage]"
      :question-type="question.config.questionType"
      :question-order-index="index"
      :question-response-count="questionResponseCount"
      @close="confirmationDialogComponent = null"
      @confirm="handleActionConfirmation"
    />
    <editing-question-with-responses-dialog
      v-if="showNoEditDialog"
      @close="showNoEditDialog = false"
    />
  </v-card>
</template>

<script>
import QuestionTypeSelector from './QuestionTypeSelector';
import QuestionTypeSelectorDialog from './QuestionTypeSelectorDialog';
import QuestionTypeIcon from '@/components/Common/QuestionTypeIcon';
import QuestionThemeSelector from './QuestionThemeSelector';
import TopWordsConfig from './QuestionConfig/TopWordsConfig';
import PieChartConfig from './QuestionConfig/PieChartConfig';
import DrawingConfig from './QuestionConfig/DrawingConfig';
import CircleQuestionConfig from './QuestionConfig/CircleQuestionConfig';
import CustomLabelsConfig from './QuestionConfig/CustomLabelsConfig';
import SliderConfig from './QuestionConfig/SliderConfig';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';
import GenderConfig from './QuestionConfig/GenderConfig';
import Rating1To5QuestionConfig from './QuestionConfig/Rating1To5QuestionConfig';
import QuestionFormActionButtons from './QuestionFormActionButtons';
import DeleteQuestionConfirmationDialog from './DeleteQuestionConfirmationDialog';
import ClearResponsesConfirmationDialog from './ClearResponsesConfirmationDialog';
import EditingQuestionWithResponsesDialog from './EditingQuestionWithResponsesDialog';
import QuestionActionConfirmationDialog from '@/mixins/QuestionActionConfirmationDialog';
import GuidanceConfig from './QuestionConfig/GuidanceConfig';
import MultipleChoiceConfig from './QuestionConfig/MultipleChoiceConfig';
import EmotionWheelConfig from './QuestionConfig/EmotionWheelConfig';
import { mapActions } from 'vuex';
const QuestionProperty = Object.freeze({
  text: 'text',
  type: 'type',
  theme: 'theme'
});
export default {
  name: 'QuestionForm',
  components: {
    QuestionTypeSelector,
    QuestionTypeSelectorDialog,
    QuestionTypeIcon,
    QuestionThemeSelector,
    TopWordsConfig,
    PieChartConfig,
    CircleQuestionConfig,
    CustomLabelsConfig,
    SliderConfig,
    DrawingConfig,
    GenderConfig,
    Rating1To5QuestionConfig,
    QuestionFormActionButtons,
    DeleteQuestionConfirmationDialog,
    ClearResponsesConfirmationDialog,
    EditingQuestionWithResponsesDialog,
    GuidanceConfig,
    MultipleChoiceConfig,
    EmotionWheelConfig
  },
  mixins: [QuestionActionConfirmationDialog],
  props: {
    question: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    targetLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showQuestionTypeSelectorDialog: false,
      formHasErrors: false,
      confirmationDialogComponent: null,
      showNoEditDialog: false
    };
  },
  computed: {
    textHasError() {
      return (
        this.formHasErrors &&
        !this.question.textValid(this.targetLanguage)
      );
    },
    // Enable use of QuestionProperty in template expressions
    questionProperty() {
      return QuestionProperty;
    },
    questionConfigComponent() {
      return QuestionTypeConfig[this.question.config.questionType]
        .configComponent;
    },
    questionIndexStyles() {
      return !this.isComplete && this.formHasErrors
        ? 'red--text'
        : 'grey--text';
    },
    questionEmpty() {
      return this.question.isEmpty(this.targetLanguage);
    },
    isComplete() {
      return this.question.isComplete(this.targetLanguage);
    },
    showActionConfirmationDialog() {
      return this.confirmationDialogComponent != null;
    },
    questionResponseCount() {
      return this.question.responseCount;
    },
    questionHasResponses() {
      return this.questionResponseCount > 0;
    }
  },
  watch: {
    question: {
      deep: true,
      handler(updated, old) {
        this.updateQuestionUnsavedChangesStatus();
        if (updated == old && this.isComplete) this.queueSave();
      }
    }
  },
  mounted() {
    if (this.question.hasUnsavedChanges) {
      this.formHasErrors = !this.isComplete;
    }
  },
  methods: {
    ...mapActions({
      saveQuestion: 'question/saveQuestion',
      deleteQuestion: 'question/deleteQuestion',
      duplicateQuestion: 'question/duplicateQuestion',
      fetchSurvey: 'survey/fetchSurvey',
      deleteQuestionResponses:
        'questionResponses/deleteQuestionResponses'
    }),
    updateQuestionUnsavedChangesStatus() {
      if (!this.questionEmpty && !this.isComplete) {
        this.question.hasUnsavedChanges = true;
      } else if (this.questionEmpty) {
        this.question.hasUnsavedChanges = false;
      }
    },
    selectorProps(selector) {
      return {
        ['hide-details']: true,
        error:
          this.formHasErrors && !this.question[`${selector}Valid`](),
        disabled: this.questionHasResponses && selector != 'theme'
      };
    },
    close() {
      this.$emit('close-question-form');
    },
    showTypeSelectorDialog() {
      this.showQuestionTypeSelectorDialog = true;
    },
    handleActionConfirmation() {
      if (
        this.confirmationDialogComponent ==
        DeleteQuestionConfirmationDialog
      ) {
        this.deleteQuestion(this.question);
        this.close();
      } else if (
        this.confirmationDialogComponent ==
        ClearResponsesConfirmationDialog
      ) {
        this.deleteQuestionResponses(this.question.id).then(() => {
          this.fetchSurvey(this.question.survey.id);
        });
      }
      this.confirmationDialogComponent = null;
    },
    handleDuplicateQuestion() {
      this.duplicateQuestion({
        question: this.question,
        placeAtIndex: this.index + 1
      }).then(res => {
        const duplicateQuestionId = res.data.id;
        this.$store
          .dispatch('survey/fetchSurvey', this.question.survey.id)
          .then(() => {
            this.$emit('focus-duplicate', duplicateQuestionId);
          });
      });
    },
    handleDeleteQuestion() {
      if (this.questionHasResponses) {
        this.confirmationDialogComponent = DeleteQuestionConfirmationDialog;
      } else {
        this.deleteQuestion(this.question);
        this.close();
      }
    },
    handleDeleteQuestionResponses() {
      this.confirmationDialogComponent = ClearResponsesConfirmationDialog;
    },
    selectedNewType(newType) {
      this.question.setConfig(newType, this.targetLanguage);
    },
    queueSave() {
      // check to see if the question already
      // has an active timer for saving and clear it if
      // it does
      if (this.question.timerId) {
        clearTimeout(this.question.timerId);
      }
      this.question.timerId = setTimeout(
        this.saveQuestion.bind(undefined, this.question),
        500
      );
    }
  }
};
</script>

<style lang="scss" scoped>
// TODO: Move to util
.has-pointer {
  cursor: pointer;
}
.question-type-content {
  > span {
    line-height: 1.5;
  }
  &:hover {
    .question-type-icon,
    span {
      color: var(--v-primary-base) !important;
    }
  }
}
</style>
