var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { "no-gutters": "" } },
        [
          _vm.loading
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-0 pl-4 pr-8 no-gutters" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-3 mt-n1", attrs: { cols: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "search",
                              dense: "",
                              "hide-details": "",
                              placeholder: _vm.searchPlaceholder,
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._t("error"),
                  _c(
                    "v-row",
                    { staticClass: "mx-0" },
                    _vm._l(_vm.loadingCardsCount, function(n) {
                      return _c(
                        "v-col",
                        { key: n, attrs: { xl: "4", lg: "4", md: "6" } },
                        [_c("survey-card-loader")],
                        1
                      )
                    }),
                    1
                  )
                ],
                2
              )
            : _c("v-data-iterator", {
                attrs: {
                  items: _vm.sortedSurveys,
                  search: _vm.surveyFilterText,
                  "no-results-text": "No matching surveys found",
                  "custom-filter": _vm.filterSurveys,
                  "hide-default-footer": "",
                  "disable-pagination": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c(
                            "v-row",
                            { staticClass: "pa-0 pl-4 pr-8 no-gutters" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pb-3 mt-n1",
                                  attrs: { cols: "3" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": "search",
                                      dense: "",
                                      "hide-details": "",
                                      placeholder: _vm.searchPlaceholder,
                                      autofocus: ""
                                    },
                                    model: {
                                      value: _vm.surveyFilterText,
                                      callback: function($$v) {
                                        _vm.surveyFilterText = $$v
                                      },
                                      expression: "surveyFilterText"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-spacer")
                            ],
                            1
                          ),
                          _vm.enableStatusFilter
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("survey-status-filter-toolbar", {
                                        attrs: {
                                          "status-counters": _vm.statusCounters
                                        },
                                        model: {
                                          value: _vm.visibleStatuses,
                                          callback: function($$v) {
                                            _vm.visibleStatuses = $$v
                                          },
                                          expression: "visibleStatuses"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [_vm._t("error")],
                                2
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "transition-group",
                            {
                              staticClass: "row mx-0",
                              attrs: {
                                name: "survey-grid",
                                tag: "div",
                                appear: ""
                              }
                            },
                            _vm._l(props.items, function(survey) {
                              return _c(
                                "v-col",
                                {
                                  key: survey.id,
                                  staticClass: "pa-4 d-flex survey-grid-item",
                                  attrs: { xl: "4", lg: "4", md: "6" }
                                },
                                [
                                  _c("survey-card", {
                                    attrs: {
                                      "show-organisation":
                                        _vm.belongsToMultipleOrganisations,
                                      "organisation-name": _vm.organisationForSurvey(
                                        survey
                                      ).name,
                                      survey: survey
                                    },
                                    on: {
                                      "survey-action-request": function(
                                        $event
                                      ) {
                                        return _vm.$emit(
                                          "survey-action-request",
                                          {
                                            action: $event,
                                            survey: survey
                                          }
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function() {
                        return [
                          _c(
                            "v-row",
                            { staticClass: "mx-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-4",
                                  attrs: { xl: "4", lg: "4", md: "6" }
                                },
                                [
                                  _c("add-survey-card", {
                                    on: {
                                      "show-survey-dialog": function($event) {
                                        return _vm.$emit(
                                          "add-survey-card-click"
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }