var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      !_vm.dataLoading
        ? _c("v-fade-transition", [
            _c(
              "div",
              { staticClass: "ml-6 mr-6 mt-8" },
              [
                _c("h1", [_vm._v(_vm._s(_vm.stats.title))]),
                _c("dashboard-view", {
                  attrs: { "display-language": _vm.displayLanguage },
                  on: { "fetch-stats-with-date": _vm.fetchStatsBetweenDates }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }