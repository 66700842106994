var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-container",
        { staticClass: "fill-height" },
        [
          _c(
            "v-row",
            { staticClass: "justify-center text-center align-center" },
            [
              _c("v-img", {
                attrs: { src: _vm.getSmileyFaceImgUrl, "max-width": "137px" }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass:
                "text-h6 font-weight-bold black--text text-center pt-2 pr-9"
            },
            [_vm._v(" " + _vm._s(_vm.averageEmotionDisplayName) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }