<template>
  <v-icon
    :size="size"
    :color="$attrs['color']"
    class="d-inline-block question-type-icon"
  >
    {{ icon }}
  </v-icon>
</template>

<script>
export default {
  name: 'QuestionTypeIcon',
  props: {
    questionType: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 22
    }
  },
  data: () => ({
    questionTypeIconMapping: {
      GENDER: 'wc',
      NUMBER_FLIPPERS: 'filter_6',
      COUNTRY: 'public',
      TOP_WORDS: 'format_list_numbered',
      PEOPLE_COUNT: 'group',
      PEOPLE_COUNT_ADULTS: 'group',
      SPIKY_BALL: 'brightness_7',
      DRAWING: 'create',
      CROWD_DOTS: 'grain',
      RATING_1_5: 'more_horiz',
      RATING_0_10: 'more_horiz',
      PIE_CHART: 'pie_chart',
      PHOTO: 'photo_camera',
      AUDIO: 'mic',
      HEART: 'favorite',
      CIRCLES_OVERLAP: 'fiber_smart_record',
      CIRCLES_PROPORTIONAL: 'hdr_weak',
      SMILEY_FACE: 'mood',
      ENCLOSING_WALLS: 'contacts',
      CLOCK: 'query_builder',
      YES_NO: 'done',
      WAVE_LINE: 'waves',
      TEXT: 'keyboard',
      EMAIL_ADDRESS: 'email',
      POSTCODE: 'pin_drop',
      SLIDER: 'power_input',
      GUIDANCE: 'notes',
      EMOTION_WHEEL: 'camera',
      MULTIPLE_CHOICE: 'list_alt'
    }
  }),
  computed: {
    icon() {
      return (
        this.questionTypeIconMapping[this.questionType] || 'help'
      );
    }
  }
};
</script>
