var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-card-text",
        [
          !_vm.isEditing
            ? _c("v-text-field", {
                attrs: {
                  "prepend-icon": "mail",
                  label: "Email Address",
                  rules: _vm.validationRules.email,
                  "validate-on-blur": "",
                  "persistent-hint": "",
                  hint: "Required *"
                },
                model: {
                  value: _vm.memberValue.email,
                  callback: function($$v) {
                    _vm.$set(_vm.memberValue, "email", $$v)
                  },
                  expression: "memberValue.email"
                }
              })
            : _vm._e(),
          _c("v-select", {
            attrs: {
              "prepend-icon": "person",
              items: _vm.userRoles,
              "item-text": "name",
              "item-value": "key",
              rules: _vm.validationRules.userRole,
              hint: _vm.selectedUserRoleDescription,
              label: "Role",
              "persistent-hint": ""
            },
            model: {
              value: _vm.memberValue.userRole,
              callback: function($$v) {
                _vm.$set(_vm.memberValue, "userRole", $$v)
              },
              expression: "memberValue.userRole"
            }
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pa-6" },
        [
          _c("v-spacer"),
          _c("v-btn", { staticClass: "mr-0", on: { click: _vm.closeDialog } }, [
            _vm._v(" Cancel ")
          ]),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: { color: "success" },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(_vm.submitButtonText) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }