var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasMultipleLanguages
    ? _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "transition-group",
            { attrs: { name: "language-status", appear: "" } },
            _vm._l(_vm.languageStatus, function(language) {
              return _c(
                "span",
                {
                  key: language.code,
                  staticClass: "language-status-item",
                  class: {
                    highlighted: _vm.highlighted(language),
                    clickable: _vm.clickable(language),
                    "mr-1": !_vm.lastLanguage(language)
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("language-clicked", language.code)
                    }
                  }
                },
                [
                  _c("language-avatar", {
                    attrs: { language: language, color: _vm.color(language) }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }