var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    [
      _vm.showName
        ? _c("span", { staticClass: "language mr-2" }, [
            _vm._v(_vm._s(_vm.language.name))
          ])
        : _vm._e(),
      _c("language-avatar", { attrs: { language: _vm.language } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }