var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex justify-space-around" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-self-center justify-self-center label text-h3 text-right"
        },
        [
          _c("localised-text", {
            staticClass: "w-100",
            attrs: {
              language: _vm.displayLanguage,
              string: _vm.circles[0].label,
              color: _vm.theme.textColor
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex mb-12 justify-content-center text-center" },
        _vm._l(_vm.circles, function(circle, index) {
          return _c("circle-svg", {
            key: index,
            attrs: {
              size: 400,
              opacity: 0.7,
              colors: circle.colors,
              "x-translation": circle.xTranslation
            }
          })
        }),
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-flex align-self-center justify-self-center justify-content-center label"
        },
        [
          _c(
            "span",
            { staticClass: "text-h3 text-left" },
            [
              _c("localised-text", {
                attrs: {
                  language: _vm.displayLanguage,
                  string: _vm.circles[1].label,
                  color: _vm.theme.textColor
                }
              })
            ],
            1
          )
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "d-flex justify-center text-h3 font-weight-bold pt-12" },
      [_vm._v(" " + _vm._s(_vm.formatPercent(_vm.overlap)) + " ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }