var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { staticClass: "pa-0 mb-4", attrs: { hover: "" } }, [
    _c(
      "div",
      {
        staticClass: "d-flex align-center",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.edit($event)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "grey lighten-2 pa-5 pl-6 pr-6 mr-4",
            class: _vm.editing ? "rounded-tl" : "rounded-l"
          },
          [
            _c("div", { staticClass: "grey--text text--darken-2" }, [
              _vm._v(" " + _vm._s(_vm.questionIndex) + " ")
            ])
          ]
        ),
        _c(
          "question-type-icon",
          _vm._b(
            {
              staticClass: "mr-4 question-type-icon",
              class: { "grey--text text--lighten-1": !_vm.visible },
              attrs: { "question-type": _vm.questionType.value }
            },
            "question-type-icon",
            _vm.questionTypeIconProps,
            false
          )
        ),
        _c(
          "div",
          {
            staticClass: "text-h6",
            class: { "grey--text text--lighten-1": !_vm.visible }
          },
          [_vm._v(" " + _vm._s(_vm.questionText) + " ")]
        ),
        !_vm.editing
          ? _c(
              "div",
              { staticClass: "ml-auto mr-4" },
              [
                _vm.visible
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "square grey--text text--darken-1",
                        on: { click: _vm.hideQuestion }
                      },
                      [_c("v-icon", [_vm._v("visibility_off")])],
                      1
                    )
                  : _c(
                      "v-btn",
                      {
                        staticClass: "square grey--text text--darken-1",
                        on: { click: _vm.showQuestion }
                      },
                      [_c("v-icon", [_vm._v("visibility")])],
                      1
                    )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm.editing
      ? _c("div", { staticClass: "pa-2" }, [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("div", { staticClass: "mr-16" }),
              _c("public-display-question-form", {
                attrs: {
                  "question-public-display-config":
                    _vm.questionPublicDisplayConfig
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex pa-2" },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "shortkey",
                      rawName: "v-shortkey",
                      value: ["esc"],
                      expression: "['esc']"
                    }
                  ],
                  staticClass: "ml-auto",
                  attrs: { small: "" },
                  on: { click: _vm.close, shortkey: _vm.close }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }