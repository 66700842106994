var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    _vm._l(_vm.genderOptions, function(genderOption, index) {
      return _c(
        "v-col",
        { key: index, staticClass: "d-flex justify-center" },
        [
          _c(
            "v-card",
            { staticClass: "text-center", attrs: { flat: "", height: "100%" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pt-0" },
                [
                  _c(_vm.componentMap[genderOption], {
                    tag: "component",
                    class: genderOption
                  }),
                  _c(
                    "div",
                    { staticClass: "text-h6 font-weight-bold black--text" },
                    [_vm._v(" " + _vm._s(_vm.totalNumber(genderOption)) + " ")]
                  ),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v(" " + _vm._s(_vm.averageNumber(genderOption)) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }