var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "p-relative pa-4 mb-4", attrs: { hover: "" } },
    [
      _c("div", { staticClass: "pa-2" }, [
        _c(
          "div",
          { staticClass: "d-flex align-center mb-4" },
          [
            _c("h2", { staticClass: "mr-8 font-weight-medium" }, [
              _vm._v(" Public Display Configuration ")
            ]),
            _c(
              "v-btn",
              {
                attrs: {
                  to: {
                    name: "public-display",
                    query: { accessCode: _vm.accessCode }
                  },
                  target: "_blank",
                  small: ""
                }
              },
              [
                _c("v-icon", { attrs: { small: "", left: "" } }, [
                  _vm._v("launch")
                ]),
                _vm._v("Open Public Display ")
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "d-flex" }, [
          _c("div", { staticClass: "flex-row mr-16" }, [
            _c(
              "p",
              { staticClass: "grey--text text--darken-1 mb-0" },
              [
                _vm._v(" Public Access URL "),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                {
                                  staticClass: "mb-1",
                                  attrs: { color: "primary", size: "18" }
                                },
                                on
                              ),
                              [_vm._v(" help ")]
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " Share this link to offer access to the public display without needing to log into muse. "
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "span",
              [
                _c(
                  "a",
                  {
                    staticClass: "mr-2",
                    attrs: { href: _vm.publicAccessUrl, target: "_blank" }
                  },
                  [_vm._v(" " + _vm._s(_vm.publicAccessUrl) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "x-small-square",
                    attrs: { "x-small": "" },
                    on: { click: _vm.copyUrl }
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { "x-small": "", icon: "", tile: "" } },
                      [_vm._v(" content_copy ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }