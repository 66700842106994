var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-card-text",
        { staticClass: "px-6" },
        [
          _c("v-text-field", {
            attrs: {
              "prepend-icon": "article",
              label: "Title",
              rules: _vm.validationRules.title,
              "persistent-hint": "",
              autofocus: "",
              hint: "Required *"
            },
            model: {
              value: _vm.model.title,
              callback: function($$v) {
                _vm.$set(_vm.model, "title", $$v)
              },
              expression: "model.title"
            }
          }),
          _vm.belongsToMultipleOrganisations
            ? _c("v-select", {
                attrs: {
                  "prepend-icon": "business",
                  items: _vm.organisations,
                  "item-text": "name",
                  "item-value": "id",
                  label: "Organisation"
                },
                on: { change: _vm.onChangeOrganisation },
                model: {
                  value: _vm.model.organisationId,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "organisationId", $$v)
                  },
                  expression: "model.organisationId"
                }
              })
            : _vm._e(),
          _c("language-selector", {
            attrs: {
              "primary-language": _vm.model.primaryLanguage,
              "secondary-languages": _vm.model.secondaryLanguages
            },
            on: {
              "update:primaryLanguage": function($event) {
                return _vm.$set(_vm.model, "primaryLanguage", $event)
              },
              "update:primary-language": function($event) {
                return _vm.$set(_vm.model, "primaryLanguage", $event)
              },
              "update:secondaryLanguages": function($event) {
                return _vm.$set(_vm.model, "secondaryLanguages", $event)
              },
              "update:secondary-languages": function($event) {
                return _vm.$set(_vm.model, "secondaryLanguages", $event)
              }
            }
          }),
          _c("survey-timeframe-selector", {
            attrs: {
              "survey-start": _vm.model.surveyStart,
              "survey-end": _vm.model.surveyEnd
            },
            on: {
              "update:surveyStart": function($event) {
                return _vm.$set(_vm.model, "surveyStart", $event)
              },
              "update:survey-start": function($event) {
                return _vm.$set(_vm.model, "surveyStart", $event)
              },
              "update:surveyEnd": function($event) {
                return _vm.$set(_vm.model, "surveyEnd", $event)
              },
              "update:survey-end": function($event) {
                return _vm.$set(_vm.model, "surveyEnd", $event)
              }
            }
          }),
          _c(
            "v-row",
            { staticClass: "d-flex flex-column", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-body-2 grey--text text--darken-2" },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("insert_photo")
                  ]),
                  _c("span", [_vm._v("Intro Background Image")])
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("image-upload-card", {
                    staticClass: "ml-8 mt-2",
                    attrs: {
                      "image-id": _vm.model.introBackgroundImageId,
                      "organisation-id": _vm.model.organisationId,
                      "image-type": _vm.imageTypes.SURVEY_BACKGROUND_IMAGE
                    },
                    on: {
                      "image-uploaded": _vm.handleImageUploaded,
                      "delete-image": _vm.handleDeleteImage
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pa-6" },
        [
          _c("v-spacer"),
          _c("v-btn", { staticClass: "mr-0", on: { click: _vm.closeForm } }, [
            _vm._v(" Cancel ")
          ]),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: { color: "success" },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(_vm.submitButtonText) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }