var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-wrapper" },
    [
      _c("PincodeInput", {
        class: { shake: _vm.animated },
        attrs: {
          placeholder: "",
          autofocus: _vm.autoFocus,
          length: _vm.accessCodeLength,
          secure: _vm.secure,
          "character-preview": false
        },
        on: { input: _vm.onInput },
        model: {
          value: _vm.code,
          callback: function($$v) {
            _vm.code = $$v
          },
          expression: "code"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }