import { render, staticRenderFns } from "./FreeTextResponsesModal.vue?vue&type=template&id=d6640750&scoped=true&"
import script from "./FreeTextResponsesModal.vue?vue&type=script&lang=js&"
export * from "./FreeTextResponsesModal.vue?vue&type=script&lang=js&"
import style0 from "./FreeTextResponsesModal.vue?vue&type=style&index=0&id=d6640750&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6640750",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VCol,VContainer,VDialog,VDivider,VList,VListItem,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/Users/allanjones/Code/deakin/muse/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d6640750')) {
      api.createRecord('d6640750', component.options)
    } else {
      api.reload('d6640750', component.options)
    }
    module.hot.accept("./FreeTextResponsesModal.vue?vue&type=template&id=d6640750&scoped=true&", function () {
      api.rerender('d6640750', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Common/FreeTextResponsesModal.vue"
export default component.exports