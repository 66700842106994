var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex align-center mb-n1" }, [
    _c(
      "div",
      [
        _c("v-icon", { attrs: { color: _vm.iconColor } }, [
          _vm._v(_vm._s(_vm.icon))
        ])
      ],
      1
    ),
    _c("div", { staticClass: "mx-4 text-body-2" }, [
      _vm._v(_vm._s(_vm.message))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }