<template>
  <svg :viewBox="`0 0 ${width} ${height}`" overflow="visible">
    <rect
      :y="linePosY"
      :width="width"
      :height="lineWidth"
      :fill="theme.slider.barColour"
    />
    <rect
      v-for="(tick, i) in ticks"
      :key="i"
      :x="tick"
      :y="tickPosY"
      :width="lineWidth"
      :height="tickHeight"
      :fill="theme.slider.tickColour"
    />
    <linearGradient id="circleFill">
      <stop offset="0" :stop-color="theme.slider.gradient.start" />
      <stop offset="100%" :stop-color="theme.slider.gradient.end" />
    </linearGradient>
    <circle
      :cx="circlePos.x"
      :cy="circlePos.y"
      :r="radius"
      fill="url(#circleFill)"
    />
  </svg>
</template>

<script>
export default {
  name: 'SliderSvg',
  props: {
    theme: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 20
    },
    lineWidth: {
      type: Number,
      default: 3
    },
    middleTick: {
      type: Boolean,
      default: false
    },
    sliderValue: {
      type: Number,
      required: true
    },
    tickHeight: {
      type: Number,
      default: 20
    }
  },
  computed: {
    circlePos() {
      return {
        x: this.width * this.sliderValue,
        y: this.height / 2
      };
    },
    linePosY() {
      return (this.height - this.lineWidth) / 2;
    },
    tickPosY() {
      return (this.height - this.tickHeight) / 2;
    },
    radius() {
      return this.height / 2;
    },
    ticks() {
      return this.middleTick
        ? [
            0,
            (this.width - this.lineWidth) / 2,
            this.width - this.lineWidth
          ]
        : [0, this.width - this.lineWidth];
    }
  }
};
</script>
