var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form", staticClass: "p-relative" },
    [
      _c(
        "v-card-text",
        [
          _c("v-text-field", {
            attrs: {
              "prepend-icon": "insert_comment",
              label: "Name",
              rules: _vm.validationRules.name,
              autofocus: "",
              hint: _vm.isRequired
            },
            model: {
              value: _vm.model.name,
              callback: function($$v) {
                _vm.$set(_vm.model, "name", $$v)
              },
              expression: "model.name"
            }
          }),
          _c("v-text-field", {
            attrs: { "prepend-icon": "domain", label: "Address" },
            model: {
              value: _vm.model.address,
              callback: function($$v) {
                _vm.$set(_vm.model, "address", $$v)
              },
              expression: "model.address"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "prepend-icon": "alternate_email",
              label: "iPad Username",
              rules: _vm.validationRules.ipadUsername,
              "persistent-hint": "",
              hint: _vm.isRequired
            },
            model: {
              value: _vm.model.ipadUsername,
              callback: function($$v) {
                _vm.$set(_vm.model, "ipadUsername", $$v)
              },
              expression: "model.ipadUsername"
            }
          }),
          _c(
            "div",
            { staticStyle: { "max-width": "50%" } },
            [
              _c("v-text-field", {
                staticClass: "password",
                attrs: {
                  "prepend-icon": "lock",
                  label: "iPad Password",
                  "append-icon": _vm.passwordVisibilityIcon,
                  type: _vm.passwordFieldType,
                  rules: _vm.validatePassword,
                  placeholder: _vm.passwordPlaceholder,
                  "persistent-hint": "",
                  hint: _vm.isRequired
                },
                on: {
                  "click:append": function($event) {
                    _vm.showPassword = !_vm.showPassword
                  },
                  focus: function($event) {
                    _vm.passwordFocus = true
                  },
                  blur: function($event) {
                    _vm.passwordFocus = false
                  }
                },
                model: {
                  value: _vm.model.ipadPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "ipadPassword", $$v)
                  },
                  expression: "model.ipadPassword"
                }
              })
            ],
            1
          ),
          _c("language-selector", {
            attrs: {
              "primary-language": _vm.model.primaryLanguage,
              "secondary-languages": _vm.model.secondaryLanguages
            },
            on: {
              "update:primaryLanguage": function($event) {
                return _vm.$set(_vm.model, "primaryLanguage", $event)
              },
              "update:primary-language": function($event) {
                return _vm.$set(_vm.model, "primaryLanguage", $event)
              },
              "update:secondaryLanguages": function($event) {
                return _vm.$set(_vm.model, "secondaryLanguages", $event)
              },
              "update:secondary-languages": function($event) {
                return _vm.$set(_vm.model, "secondaryLanguages", $event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pa-6" },
        [
          _vm.showDisclaimerUpdatePrompt
            ? _c("form-summary-message", {
                attrs: { icon: "warning", message: _vm.disclaimerUpdatePrompt }
              })
            : _vm._e(),
          _c("v-spacer"),
          _c("v-btn", { staticClass: "mr-0", on: { click: _vm.closeDialog } }, [
            _vm._v(" Cancel ")
          ]),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: { color: "success" },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(_vm.submitButtonText) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }