var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.verticalOrientation
    ? _c(
        "v-col",
        { staticClass: "text-center" },
        [
          _c(
            "p",
            {
              class: [
                "mb-2 grey--text text-caption",
                !_vm.disabled
                  ? _vm.multipleChoiceClass
                    ? "text--darken-2"
                    : "text--lighten-1"
                  : ""
              ]
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.disabled || _vm.isMax, small: "" },
              on: {
                click: function($event) {
                  return _vm.update(++_vm.number)
                }
              }
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("arrow_upward")])],
            1
          ),
          _c("p", { class: ["my-4", _vm.disabled ? "grey--text" : ""] }, [
            _vm._v(" " + _vm._s(_vm.number) + " ")
          ]),
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.disabled || _vm.isMin, small: "" },
              on: {
                click: function($event) {
                  return _vm.update(--_vm.number)
                }
              }
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("arrow_downward")])
            ],
            1
          )
        ],
        1
      )
    : _c("div", [
        _c(
          "p",
          {
            class: [
              "mt-3 mb-2 grey--text text-caption",
              !_vm.disabled
                ? _vm.multipleChoiceClass
                  ? "text--darken-2"
                  : "text--lighten-1"
                : ""
            ]
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        ),
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c(
              "v-btn",
              {
                staticClass: "square",
                attrs: { disabled: _vm.disabled || _vm.isMin, small: "" },
                on: {
                  click: function($event) {
                    return _vm.update(--_vm.number)
                  }
                }
              },
              [_c("v-icon", { attrs: { small: "" } }, [_vm._v("remove")])],
              1
            ),
            _c(
              "p",
              {
                class: [
                  "my-0",
                  "mx-3",
                  "text-body-2",
                  _vm.disabled ? "grey--text" : ""
                ]
              },
              [_vm._v(" " + _vm._s(_vm.number) + " ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "square",
                attrs: { disabled: _vm.disabled || _vm.isMax, small: "" },
                on: {
                  click: function($event) {
                    return _vm.update(++_vm.number)
                  }
                }
              },
              [_c("v-icon", { attrs: { small: "" } }, [_vm._v("add")])],
              1
            )
          ],
          1
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }