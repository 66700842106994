var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("word-list-config", {
            attrs: { words: _vm.words, disabled: _vm.questionHasResponses },
            on: { "words-updated": _vm.wordsUpdated }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "2" } },
        [
          _c(
            "v-row",
            { staticClass: "d-flex flex-row-reverse" },
            [
              _c("word-settings-controller", {
                attrs: {
                  count: _vm.wordCount,
                  range: _vm.config.wordRange,
                  title: "Words",
                  disabled: _vm.questionHasResponses
                },
                on: { "set-value": _vm.setWordCount }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }