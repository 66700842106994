var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDialog
    ? _c("action-modal", {
        attrs: { show: "", title: _vm.modalTitle },
        on: {
          cancel: function($event) {
            return _vm.closeDialog()
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function() {
                return [
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.modalText) + " "),
                    _vm.showFormattedResponses
                      ? _c("span", [
                          _c("br"),
                          _vm._v(_vm._s(_vm.formattedResponses) + " ")
                        ])
                      : _vm._e()
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: _vm.actionButtonColor },
                      on: { click: _vm.confirmDialogAction }
                    },
                    [_vm._v(" " + _vm._s(_vm.action) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          3228369889
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }