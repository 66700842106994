var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "public-display-form" },
    [
      _c(
        "v-form",
        { ref: "form", staticClass: "mr-auto" },
        [
          _c("p", { staticClass: "grey--text text--darken-1 mb-2" }, [
            _vm._v("Slide titles")
          ]),
          _vm._l(_vm.languages, function(language, i) {
            return _c("div", { key: language }, [
              _c(
                "div",
                { staticClass: "d-flex align-center mb-2" },
                [
                  _c("language-avatar", {
                    staticClass: "mr-4",
                    attrs: { language: { code: "" + language } }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      autofocus: i === 0
                    },
                    on: { input: _vm.queueSave },
                    model: {
                      value: _vm.customTitle.languageMap[language],
                      callback: function($$v) {
                        _vm.$set(_vm.customTitle.languageMap, language, $$v)
                      },
                      expression: "customTitle.languageMap[language]"
                    }
                  })
                ],
                1
              )
            ])
          }),
          _vm.questionTypeDef.hasAdditionalPublicDisplayConfig
            ? _c(
                "div",
                [
                  _c(_vm.questionTypeDef.publicDisplayOptionsComponent, {
                    tag: "component",
                    staticClass:
                      "d-flex flex-column flex-grow-1 justify-center",
                    attrs: {
                      "public-display-config": _vm.questionPublicDisplayConfig
                    },
                    on: { "options-changed": _vm.queueSave }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }