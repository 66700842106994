var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "constrained-area-parent" },
    [
      _vm._l(_vm.urlsToUse, function(url, index) {
        return _c("link", {
          key: index,
          attrs: { rel: "preload", href: url, as: "image" }
        })
      }),
      _c(
        "div",
        { class: _vm.gridClass },
        _vm._l(_vm.urlsToUse, function(url, index) {
          return _c("div", { key: index }, [
            _c("img", { staticClass: "grid-img", attrs: { src: url } })
          ])
        }),
        0
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }