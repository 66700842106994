var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex" },
      _vm._l(_vm.circles, function(circle, index) {
        return _c(
          "v-col",
          {
            key: index,
            staticClass: "d-flex flex-column justify-center py-0",
            attrs: { cols: "12", md: "6" }
          },
          [
            _c("circle-svg", {
              attrs: {
                size: 600,
                scale: circle.average,
                colors: _vm.circleColors(circle)
              }
            }),
            _c("div", { staticClass: "align-self-center" }, [
              _c(
                "div",
                { staticClass: "text-h3 font-weight-bold mb-3" },
                [
                  _c("localised-text", {
                    attrs: {
                      language: _vm.displayLanguage,
                      string: circle.label,
                      color: _vm.theme.textColor
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "text-h4 text-center" }, [
                _vm._v(" " + _vm._s(_vm.formatPercent(circle.average)) + " ")
              ])
            ])
          ],
          1
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }