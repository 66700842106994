var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.show, width: "65%", persistent: "" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          $event.stopPropagation()
          return _vm.close()
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "text-h5 grey lighten-2",
              attrs: { "primary-title": "" }
            },
            [
              _c("question-type-icon", {
                staticClass: "mr-2 question-type-icon",
                attrs: { "question-type": _vm.selectedQuestionType }
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.questionTypeDisplayName(_vm.selectedQuestionType)
                  ) +
                  " Question Preview "
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pb-0 preview-card" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-n5 mr-5 question-type-column",
                      attrs: { cols: "3" }
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "question-type-list" },
                        [
                          _c("v-subheader", [_vm._v("Question Types")]),
                          _c(
                            "v-list-item-group",
                            {
                              attrs: {
                                value: "questionTypes",
                                color: "primary"
                              },
                              model: {
                                value: _vm.selectedTypeIndex,
                                callback: function($$v) {
                                  _vm.selectedTypeIndex = $$v
                                },
                                expression: "selectedTypeIndex"
                              }
                            },
                            _vm._l(_vm.questionTypes, function(type, i) {
                              return _c(
                                "v-list-item",
                                {
                                  key: "question-type-selection-" + i,
                                  on: {
                                    click: function($event) {
                                      return _vm.setSelectedQuestionType(type)
                                    }
                                  }
                                },
                                [
                                  _c("question-type-icon", {
                                    staticClass: "mr-2 question-type-icon",
                                    attrs: { "question-type": type }
                                  }),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.questionTypeDisplayName(type)
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "9" } },
                    _vm._l(_vm.questionTypes, function(type, i) {
                      return _c(
                        "div",
                        { key: i },
                        [
                          type === _vm.selectedQuestionType
                            ? _c("question-preview-video", {
                                attrs: { "question-type": type }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    disabled: !_vm.questionTypeChanged
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectQuestionType()
                    }
                  }
                },
                [_vm._v(" Select Type ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }