var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { bottom: "", right: "", absolute: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "menu-button white--text p-absolute",
                    attrs: { icon: "" }
                  },
                  on
                ),
                [_c("v-icon", [_vm._v("more_vert")])],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "v-list",
        { staticClass: "menu-list" },
        _vm._l(_vm.listItems, function(item, i) {
          return _c(
            "v-list-item",
            {
              key: i,
              on: {
                click: function($event) {
                  return _vm.$emit("action-click", item.action)
                }
              }
            },
            [
              _c(
                "v-list-item-content",
                { class: _vm.actionLabelTextClasses(item) },
                [_vm._v(" " + _vm._s(_vm.actionLabelText(item)) + " ")]
              ),
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", {
                    class: _vm.actionIconClasses(item),
                    domProps: { textContent: _vm._s(item.icon) }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }