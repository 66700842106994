<template>
  <div>
    <div>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="8">
          <slider-svg
            :theme="theme"
            :width="800"
            :height="42"
            :tick-height="36"
            :line-width="12"
            :middle-tick="!!questionStat.middleWord"
            :slider-value="questionStat.average"
          />
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
      <div class="mt-12 pt-12 d-flex justify-space-between">
        <div
          v-for="(sliderPoint, index) in sliderPoints"
          :key="index"
          class="text-h3 text-center label"
        >
          <localised-text
            :language="displayLanguage"
            :string="sliderPoint.text"
            :color="theme.textColor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalisedText from '@/components/Common/LocalisedText';
import SliderSvg from '@/components/Visualisations/SliderSvg';

export default {
  name: 'SliderPublicDisplayItem',
  components: {
    LocalisedText,
    SliderSvg
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    },
    theme: {
      type: Object,
      required: true
    }
  },
  computed: {
    sliderPoints() {
      const options = [
        {
          position: 'left',
          text: this.questionStat.leftWord
        },
        {
          position: 'right',
          text: this.questionStat.rightWord
        }
      ];

      if (
        this.questionStat.middleWord &&
        this.questionStat.middleWord.languageMap[this.displayLanguage]
      ) {
        options.splice(1, 0, {
          position: 'middle',
          text: this.questionStat.middleWord
        });
      }

      return options;
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  min-width: 400px;
}
</style>
