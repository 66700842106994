var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rating-1-to-5-question-config" },
    [
      _c("v-checkbox", {
        attrs: { label: "Override Labels", disabled: _vm.questionHasResponses },
        on: {
          change: function($event) {
            return _vm.configUpdated()
          }
        },
        model: {
          value: _vm.configModel.overrideLabels,
          callback: function($$v) {
            _vm.$set(_vm.configModel, "overrideLabels", $$v)
          },
          expression: "configModel.overrideLabels"
        }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "2" } },
            [
              _c("v-text-field", {
                staticClass: "text-body-2",
                attrs: {
                  label: "Left Label",
                  disabled: !_vm.value.overrideLabels,
                  filled: !_vm.value.overrideLabels
                },
                on: {
                  change: function($event) {
                    return _vm.configUpdated()
                  }
                },
                model: {
                  value: _vm.configModel.left.languageMap[_vm.primaryLanguage],
                  callback: function($$v) {
                    _vm.$set(
                      _vm.configModel.left.languageMap,
                      _vm.primaryLanguage,
                      $$v
                    )
                  },
                  expression: "configModel.left.languageMap[primaryLanguage]"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "2" } },
            [
              _c("v-text-field", {
                staticClass: "text-body-2",
                attrs: {
                  label: "Right Label",
                  disabled: !_vm.value.overrideLabels,
                  filled: !_vm.value.overrideLabels
                },
                on: {
                  change: function($event) {
                    return _vm.configUpdated()
                  }
                },
                model: {
                  value: _vm.configModel.right.languageMap[_vm.primaryLanguage],
                  callback: function($$v) {
                    _vm.$set(
                      _vm.configModel.right.languageMap,
                      _vm.primaryLanguage,
                      $$v
                    )
                  },
                  expression: "configModel.right.languageMap[primaryLanguage]"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }