var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("public-display-config-card", {
        staticClass: "mb-8",
        attrs: { survey: _vm.survey }
      }),
      _c(
        "div",
        { staticClass: "mb-8" },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "h3",
                {
                  staticClass:
                    "grey--text text--darken-1 mb-4 font-weight-medium d-flex"
                },
                [
                  _c("v-icon", { staticClass: "grey--text pr-2" }, [
                    _vm._v("visibility")
                  ]),
                  _vm._v(" Visible Slides ")
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-auto grey--text text--darken-1",
                  attrs: { disabled: !_vm.hasVisibleQuestions },
                  on: { click: _vm.hideAll }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("visibility_off")
                  ]),
                  _vm._v(" Hide all ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "draggable",
            {
              attrs: {
                group: "public-display-questions",
                list: _vm.visibleQuestions
              },
              on: {
                add: _vm.updateVisibleQuestions,
                remove: _vm.updateVisibleQuestions,
                update: _vm.updateVisibleQuestions
              }
            },
            _vm._l(_vm.visibleQuestions, function(question) {
              return _c("public-display-question", {
                key: question.id,
                attrs: {
                  id: "item-" + question.id,
                  "item-id": question.id,
                  "question-public-display-config": _vm.questionPublicDisplayConfig(
                    question
                  ),
                  "target-language": _vm.survey.primaryLanguage,
                  index: _vm.visibleIndex(question),
                  visible: true,
                  editing: _vm.isEditingItem(question.id)
                },
                on: {
                  "hide-public-display-question": _vm.hideQuestion,
                  edit: _vm.editItem,
                  close: _vm.closeItem
                }
              })
            }),
            1
          ),
          !_vm.hasVisibleQuestions
            ? _c("div", [
                _c("p", { staticClass: "grey--text" }, [
                  _vm._v(
                    " No questions have been added to the public display. "
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "h3",
            {
              staticClass:
                "grey--text text--darken-1 mb-4 font-weight-medium d-flex"
            },
            [
              _c("v-icon", { staticClass: "grey--text pr-2" }, [
                _vm._v("visibility_off")
              ]),
              _vm._v(" Hidden Slides ")
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto grey--text text--darken-1",
              attrs: { disabled: _vm.hasVisibleQuestions },
              on: { click: _vm.showAll }
            },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("visibility")]),
              _vm._v(" Show all ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "draggable",
        {
          attrs: {
            group: "public-display-questions",
            list: _vm.hiddenQuestions
          }
        },
        _vm._l(_vm.hiddenQuestions, function(question) {
          return _c("public-display-question", {
            key: question.id,
            attrs: {
              id: "item-" + question.id,
              "item-id": question.id,
              "question-public-display-config": _vm.questionPublicDisplayConfig(
                question
              ),
              "target-language": _vm.survey.primaryLanguage,
              index: null,
              visible: false,
              editing: _vm.isEditingItem(question.id)
            },
            on: {
              "show-public-display-question": _vm.showQuestion,
              edit: _vm.editItem,
              close: _vm.closeItem
            }
          })
        }),
        1
      ),
      _vm.hiddenQuestions.length === 0
        ? _c("div", [
            _c("p", { staticClass: "grey--text" }, [
              _vm._v(" All questions have been added to the public display. ")
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }