var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c(
        "v-select",
        _vm._b(
          {
            staticClass: "text-body-2",
            attrs: {
              items: _vm.questionTypes,
              "item-value": "value",
              "item-text": "displayName",
              label: "Question Type"
            },
            on: { input: _vm.changedType },
            scopedSlots: _vm._u(
              [
                _vm.selectedType
                  ? {
                      key: "selection",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("question-type-icon", {
                            staticClass: "mr-2",
                            attrs: { "question-type": item.value, size: 18 }
                          }),
                          _c("span", [_vm._v(_vm._s(item.displayName))])
                        ]
                      }
                    }
                  : null,
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("question-type-icon", {
                        staticClass: "mr-3",
                        attrs: { "question-type": item.value }
                      }),
                      _c("span", [_vm._v(_vm._s(item.displayName))])
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedType,
              callback: function($$v) {
                _vm.selectedType = $$v
              },
              expression: "selectedType"
            }
          },
          "v-select",
          _vm.$attrs,
          false
        )
      ),
      _c(
        "div",
        { staticClass: "pt-3" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.showQuestionTypeSelectorDialog()
                              }
                            }
                          },
                          on
                        ),
                        [_c("v-icon", [_vm._v("open_in_new")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Select via dialog")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }