var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "heart-container d-flex flex-column justify-center mx-auto"
      },
      [
        _c("filled-heart", {
          staticClass: "filled-heart",
          attrs: {
            "fill-percent": _vm.questionStat.average,
            "fill-color": _vm.theme.heart.fillColor,
            "background-color": _vm.theme.heart.backgroundColor
          }
        }),
        _c(
          "div",
          {
            staticClass:
              "text-h3 white--text text-center font-weight-bold pt-12",
            class: _vm.theme.textColor
          },
          [
            _vm._v(
              " " + _vm._s(_vm.formatPercent(_vm.questionStat.average)) + " "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }