var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "pl-6" },
            [
              _c(
                "v-col",
                {
                  staticClass: "font-weight-bold text-overline ml-10",
                  attrs: { cols: "auto" }
                },
                [_vm._v(" Type ")]
              ),
              _c("v-col", { staticClass: "font-weight-bold text-overline" }, [
                _vm._v(" Question in Primary Language ")
              ]),
              _c("v-col", { staticClass: "font-weight-bold text-overline" }, [
                _vm._v(" " + _vm._s(_vm.languageName) + " Translation ")
              ]),
              _c("v-col", { attrs: { cols: "1" } })
            ],
            1
          ),
          _vm._l(_vm.savedQuestions, function(question, index) {
            return _c(
              "v-row",
              { key: index + question.id, staticClass: "translation-row" },
              [
                question.userConfigurable
                  ? _c(
                      "v-container",
                      { staticClass: "item-container" },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "px-6" },
                              [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center question-type mr-4"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "question-number grey--text text--darken-1"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.questionNumber(question)
                                              ) +
                                              ". "
                                          )
                                        ]
                                      ),
                                      _c("question-type-icon", {
                                        attrs: {
                                          "question-type":
                                            question.config.questionType,
                                          size: 30
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.showQuestionPreview(
                                              question.config.questionType
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-grow-1 align-center"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "details text-body-1 black--text ml-2"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                question.text.languageMap[
                                                  _vm.primaryLanguage
                                                ]
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("v-text-field", {
                                        staticClass: "details mb-n3 ml-n2",
                                        attrs: {
                                          placeholder:
                                            _vm.languageName + " translation",
                                          dense: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.saveTranslation(
                                              question,
                                              "title"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            question.text.languageMap[
                                              _vm.translationLanguage
                                            ],
                                          callback: function($$v) {
                                            _vm.$set(
                                              question.text.languageMap,
                                              _vm.translationLanguage,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                    question.text.languageMap[translationLanguage]\n                  "
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "update-progress" },
                                    [
                                      _vm.fieldIsLoading(question, "title")
                                        ? _c("v-progress-circular", {
                                            attrs: {
                                              color: "primary",
                                              indeterminate: ""
                                            }
                                          })
                                        : _vm.fieldIsEdited(question, "title")
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "update-progress green--text"
                                            },
                                            [_vm._v(" done ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]),
                                _vm.hasTranslatableFields(question)
                                  ? _c(
                                      "v-container",
                                      {
                                        staticClass: "pl-0 pb-0 pt-6",
                                        attrs: { fluid: "" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mb-2 text-caption grey--text text--darken-2"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.translationFieldsLabel(
                                                    question
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _vm.isVerticalTextFieldQuestion(
                                          question
                                        )
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.translationFields(question),
                                                function(field) {
                                                  return _c(
                                                    "v-row",
                                                    {
                                                      key:
                                                        field.key +
                                                        index +
                                                        question.id,
                                                      staticClass: "mt-n2"
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "8" }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                field.value
                                                                  .languageMap[
                                                                  _vm
                                                                    .primaryLanguage
                                                                ],
                                                              dense: ""
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                return _vm.saveTranslation(
                                                                  question,
                                                                  field.key
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                field.value
                                                                  .languageMap[
                                                                  _vm
                                                                    .translationLanguage
                                                                ],
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  field.value
                                                                    .languageMap,
                                                                  _vm.translationLanguage,
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "\n                        field.value.languageMap[translationLanguage]\n                      "
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm.fieldIsLoading(
                                                        question,
                                                        field.key
                                                      )
                                                        ? _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                indeterminate:
                                                                  ""
                                                              }
                                                            }
                                                          )
                                                        : _vm.fieldIsEdited(
                                                            question,
                                                            field.key
                                                          )
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "update-progress green--text pt-6"
                                                            },
                                                            [_vm._v(" done ")]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _c(
                                              "v-row",
                                              _vm._l(
                                                _vm.translationFields(question),
                                                function(field) {
                                                  return _c(
                                                    "v-col",
                                                    {
                                                      key:
                                                        field.key +
                                                        index +
                                                        question.id,
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: _vm.countColsForSmallScreen(
                                                          question
                                                        ),
                                                        md: _vm.countColsForMediumScreen(
                                                          question
                                                        )
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex"
                                                        },
                                                        [
                                                          _vm.isTextAreaQuestion(
                                                            question
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "prompt-text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        question
                                                                          .config
                                                                          .prompt
                                                                          .languageMap[
                                                                          _vm
                                                                            .primaryLanguage
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.isTextAreaQuestion(
                                                            question
                                                          )
                                                            ? _c("v-textarea", {
                                                                staticClass:
                                                                  "ml-8 mr-n3 mt-n1",
                                                                attrs: {
                                                                  placeholder:
                                                                    _vm.languageName +
                                                                    " translation",
                                                                  dense: ""
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveTranslation(
                                                                      question,
                                                                      field.key
                                                                    )
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    field.value
                                                                      .languageMap[
                                                                      _vm
                                                                        .translationLanguage
                                                                    ],
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      field
                                                                        .value
                                                                        .languageMap,
                                                                      _vm.translationLanguage,
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "\n                        field.value.languageMap[translationLanguage]\n                      "
                                                                }
                                                              })
                                                            : _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      field
                                                                        .value
                                                                        .languageMap[
                                                                        _vm
                                                                          .primaryLanguage
                                                                      ],
                                                                    dense: ""
                                                                  },
                                                                  on: {
                                                                    input: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.saveTranslation(
                                                                        question,
                                                                        field.key
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      field
                                                                        .value
                                                                        .languageMap[
                                                                        _vm
                                                                          .translationLanguage
                                                                      ],
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        field
                                                                          .value
                                                                          .languageMap,
                                                                        _vm.translationLanguage,
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "\n                        field.value.languageMap[translationLanguage]\n                      "
                                                                  }
                                                                }
                                                              ),
                                                          _c(
                                                            "div",
                                                            {
                                                              class: _vm.additionalFieldProgressClass(
                                                                question
                                                              )
                                                            },
                                                            [
                                                              _vm.fieldIsLoading(
                                                                question,
                                                                field.key
                                                              )
                                                                ? _c(
                                                                    "v-progress-circular",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        indeterminate:
                                                                          ""
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm.fieldIsEdited(
                                                                    question,
                                                                    field.key
                                                                  )
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "update-progress green--text"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " done "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.hasReadonlyConfig(question)
                                  ? _c(
                                      "v-container",
                                      {
                                        staticClass: "pl-0 pb-0 pt-6",
                                        attrs: { fluid: "" }
                                      },
                                      [
                                        _c(
                                          _vm.questionConfigComponent(question),
                                          {
                                            tag: "component",
                                            attrs: {
                                              "target-language":
                                                _vm.translationLanguage
                                            }
                                          }
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      ),
      _vm.selectedQuestionType
        ? _c("question-preview-dialog", {
            attrs: {
              "question-type": _vm.selectedQuestionType,
              show: _vm.selectedQuestionType
            },
            on: { "close-question-preview": _vm.closeQuestionPreview }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }