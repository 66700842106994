var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "8" } },
        [
          _c("vertical-bar-chart", {
            attrs: { data: _vm.chartData, config: _vm.chartConfig }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c(
            "v-container",
            { staticClass: "fill-height" },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c("v-card-text", [
                    _c("div", { staticClass: "text-body-1" }, [
                      _vm._v(" " + _vm._s(_vm.accompanyingStatTitle) + " ")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center title black--text text-no-wrap font-weight-bold"
                      },
                      [_vm._v(" " + _vm._s(_vm.accompanyingStatValue) + " ")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }