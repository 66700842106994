var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-n12" }, [
    _c(
      "div",
      { staticClass: "d-flex justify-center align-center" },
      [
        _c("emotion-wheel-public-display-legend", {
          staticClass: "mt-6",
          attrs: {
            items: _vm.legendItems.left,
            "display-language": _vm.displayLanguage,
            "text-color": _vm.theme.textColor,
            left: ""
          }
        }),
        _c(
          "div",
          { staticClass: "mx-16", staticStyle: { width: "40%" } },
          [
            _c("emotion-wheel", {
              staticClass: "flex-grow-1 mb-12",
              attrs: {
                slices: _vm.chartSlices,
                size: 700,
                "show-labels": false
              }
            })
          ],
          1
        ),
        _c("emotion-wheel-public-display-legend", {
          staticClass: "mt-6",
          attrs: {
            items: _vm.legendItems.right,
            "display-language": _vm.displayLanguage,
            right: "",
            "text-color": _vm.theme.textColor
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }