import { render, staticRenderFns } from "./OrganisationSettingsLoader.vue?vue&type=template&id=92064872&functional=true&"
import script from "./OrganisationSettingsLoader.vue?vue&type=script&lang=js&"
export * from "./OrganisationSettingsLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VRow,VSimpleTable,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/Users/allanjones/Code/deakin/muse/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('92064872')) {
      api.createRecord('92064872', component.options)
    } else {
      api.rerender('92064872', component.options)
    }
    module.hot.accept("./OrganisationSettingsLoader.vue?vue&type=template&id=92064872&functional=true&", function () {
      api.rerender('92064872', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Organisation/Loader/OrganisationSettingsLoader.vue"
export default component.exports