var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-4" },
    [
      _c(
        "v-row",
        { staticClass: "flex-nowrap", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: [_vm.questionIndexStyles, "mr-5 mt-3"],
              attrs: { cols: "auto" }
            },
            [_vm._v(" " + _vm._s(_vm.index) + ". ")]
          ),
          _c(
            "v-col",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-2",
                      attrs: { "no-gutters": "", justify: "space-between" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            staticClass: "text-h6 pt-0",
                            attrs: {
                              error: _vm.textHasError,
                              "hide-details": "",
                              autofocus: ""
                            },
                            model: {
                              value:
                                _vm.question.text.languageMap[
                                  _vm.targetLanguage
                                ],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.question.text.languageMap,
                                  _vm.targetLanguage,
                                  $$v
                                )
                              },
                              expression:
                                "question.text.languageMap[targetLanguage]"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("question-form-action-buttons", {
                            attrs: {
                              "question-has-responses": _vm.questionHasResponses
                            },
                            on: {
                              delete: _vm.handleDeleteQuestion,
                              "clear-responses":
                                _vm.handleDeleteQuestionResponses,
                              duplicate: _vm.handleDuplicateQuestion
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "question-type-selector",
                            _vm._b(
                              {
                                on: {
                                  "show-question-type-selector-dialog":
                                    _vm.showTypeSelectorDialog,
                                  change: _vm.selectedNewType
                                },
                                model: {
                                  value: _vm.question.config.questionType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.question.config,
                                      "questionType",
                                      $$v
                                    )
                                  },
                                  expression: "question.config.questionType"
                                }
                              },
                              "question-type-selector",
                              _vm.selectorProps(_vm.questionProperty.type),
                              false
                            )
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "question-theme-selector",
                            _vm._b(
                              {
                                model: {
                                  value: _vm.question.theme,
                                  callback: function($$v) {
                                    _vm.$set(_vm.question, "theme", $$v)
                                  },
                                  expression: "question.theme"
                                }
                              },
                              "question-theme-selector",
                              _vm.selectorProps(_vm.questionProperty.theme),
                              false
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.question.hasAdditionalConfig()
                    ? _c(
                        "div",
                        [
                          _c(_vm.questionConfigComponent, {
                            tag: "component",
                            attrs: {
                              "question-has-responses":
                                _vm.questionHasResponses,
                              "target-language": _vm.targetLanguage
                            },
                            model: {
                              value: _vm.question.config,
                              callback: function($$v) {
                                _vm.$set(_vm.question, "config", $$v)
                              },
                              expression: "question.config"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  class: [
                    _vm.formHasErrors || _vm.questionHasResponses
                      ? "justify-space-between mt-6"
                      : "justify-end",
                    "d-flex"
                  ]
                },
                [
                  _vm.questionHasResponses
                    ? _c("div", { staticClass: "d-flex align-center" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" This question has "),
                            _c(
                              "span",
                              { staticClass: "font-weight-bold text-body-2" },
                              [_vm._v(_vm._s(_vm.responseCountText))]
                            ),
                            _vm._v(" from surveys and cannot be edited "),
                            _c(
                              "v-icon",
                              {
                                staticClass: "clickable",
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.showNoEditDialog = true
                                  }
                                }
                              },
                              [_vm._v("help")]
                            )
                          ],
                          1
                        )
                      ])
                    : !_vm.isComplete && _vm.formHasErrors
                    ? _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-4", attrs: { color: "red" } },
                            [_vm._v("error")]
                          ),
                          _vm._v(" Question is missing required details ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "shortkey",
                          rawName: "v-shortkey",
                          value: ["esc"],
                          expression: "['esc']"
                        }
                      ],
                      attrs: { small: "" },
                      on: { click: _vm.close, shortkey: _vm.close }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showQuestionTypeSelectorDialog
        ? _c("question-type-selector-dialog", {
            attrs: {
              "pre-dialog-selected-type": _vm.question.type(),
              show: _vm.showQuestionTypeSelectorDialog
            },
            on: {
              "update:show": function($event) {
                _vm.showQuestionTypeSelectorDialog = $event
              },
              "select-new-question-type": _vm.selectedNewType
            }
          })
        : _vm._e(),
      _vm.showActionConfirmationDialog
        ? _c(_vm.confirmationDialogComponent, {
            tag: "component",
            attrs: {
              "question-title":
                _vm.question.text.languageMap[_vm.targetLanguage],
              "question-type": _vm.question.config.questionType,
              "question-order-index": _vm.index,
              "question-response-count": _vm.questionResponseCount
            },
            on: {
              close: function($event) {
                _vm.confirmationDialogComponent = null
              },
              confirm: _vm.handleActionConfirmation
            }
          })
        : _vm._e(),
      _vm.showNoEditDialog
        ? _c("editing-question-with-responses-dialog", {
            on: {
              close: function($event) {
                _vm.showNoEditDialog = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }