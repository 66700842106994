var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "v-row",
          [
            _c("v-col", { attrs: { cols: "2" } }),
            _c(
              "v-col",
              { attrs: { cols: "8" } },
              [
                _c("slider-svg", {
                  attrs: {
                    theme: _vm.theme,
                    width: 800,
                    height: 42,
                    "tick-height": 36,
                    "line-width": 12,
                    "middle-tick": !!_vm.questionStat.middleWord,
                    "slider-value": _vm.questionStat.average
                  }
                })
              ],
              1
            ),
            _c("v-col", { attrs: { cols: "2" } })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-12 pt-12 d-flex justify-space-between" },
          _vm._l(_vm.sliderPoints, function(sliderPoint, index) {
            return _c(
              "div",
              { key: index, staticClass: "text-h3 text-center label" },
              [
                _c("localised-text", {
                  attrs: {
                    language: _vm.displayLanguage,
                    string: sliderPoint.text,
                    color: _vm.theme.textColor
                  }
                })
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }