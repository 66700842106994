var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticClass: "text-caption grey--text text--darken-2" }, [
        _vm._v("Background Image")
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("image-upload-card", {
                attrs: {
                  disable: _vm.questionHasResponses,
                  "image-id": _vm.value.backgroundImageId,
                  "organisation-id": _vm.survey.organisationId,
                  "image-type": _vm.imageTypes.QUESTION_IMAGE
                },
                on: {
                  "image-uploaded": _vm.handleImageUploaded,
                  "delete-image": _vm.handleDeleteImage
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }