<template>
  <div>
    <v-row>
      <v-col cols="5">
        <p class="mb-0 text-caption grey--text text--darken-2">
          Response Options
        </p>
        <v-row v-for="i in optionCount" :key="i">
          <v-col cols="12">
            <span class="text-body-2">{{ i }}.</span>
            <v-text-field
              :v-model="option(i)"
              class="text-body-2 mt-n10 pl-5 mb-n8"
              :value="option(i)"
              :disabled="questionHasResponses"
              height="34"
              @input="updateOption(i, $event)"
              @focus="optionsFocused = true"
              @blur="optionsFocused = false"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="7">
        <div class="d-flex">
          <word-settings-controller
            class="ml-6 mr-10"
            :count="optionCount"
            :range="config.wordRange"
            title="Options"
            :disabled="questionHasResponses"
            :multiple-choice-class="true"
            orientation="horizontal"
            @set-value="setOptionCount"
          />
          <div>
            <p
              class="pt-3 mb-0 pb-2 text-caption grey--text text--darken-2"
            >
              Selection Type
            </p>
            <v-btn-toggle v-model="value.canSelectMultiple">
              <v-btn
                v-for="(button, idx) in buttonLabels"
                :key="idx"
                :value="button.canSelectMultiple"
                class="text-capitalize text-body-2"
                small
                :disabled="questionHasResponses"
              >
                {{ button.label }}
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WordSettingsController from './WordSettingsController.vue';
export default {
  name: 'MultipleChoiceConfig',
  components: { WordSettingsController },
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: true
    },
    targetLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      options: this.value.options,
      optionCount: this.value.options.length,
      config: {
        wordRange: { min: 2, max: 6 }
      },
      buttonLabels: [
        {
          canSelectMultiple: false,
          label: 'Select One'
        },
        {
          canSelectMultiple: true,
          label: 'Select Multiple'
        }
      ],
      optionsFocused: false
    };
  },
  computed: {
    primaryLanguage() {
      return this.targetLanguage;
    }
  },
  watch: {
    value: {
      deep: true,
      handler(updated) {
        if (!this.optionsFocused) {
          this.options = updated.options;
        }
      }
    },
    options: {
      handler(updated) {
        this.value.options = updated;
      }
    }
  },
  methods: {
    updateOption(optionNumber, value) {
      this.options[optionNumber - 1].languageMap[
        this.targetLanguage
      ] = value;
      this.configUpdated();
    },
    configUpdated() {
      const config = {
        questionType: this.value.questionType,
        options: this.options,
        canSelectMultiple: this.value.canSelectMultiple
      };
      this.$emit('input', config);
      this.$emit('change', config);
    },
    setOptionCount(optionCount) {
      this.optionCount = optionCount;
      this.adjustWordInputsToMatchCount();
      this.configUpdated();
    },
    adjustWordInputsToMatchCount() {
      if (this.options.length < this.optionCount) {
        while (this.options.length < this.optionCount) {
          this.addEmptyWord();
        }
      } else if (this.options.length > this.optionCount) {
        while (this.options.length > this.optionCount) {
          this.removeLastWord();
        }
      }
    },
    addEmptyWord() {
      this.options.push({
        languageMap: { [this.primaryLanguage]: '' }
      });
    },
    removeLastWord() {
      this.options.pop();
    },
    option(index) {
      return index > this.options.length
        ? ''
        : this.options[index - 1].languageMap[this.primaryLanguage];
    }
  }
};
</script>
