var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [
                _c(
                  "div",
                  _vm._g({}, on),
                  [
                    _c("v-icon", { attrs: { left: "", color: "amber" } }, [
                      _vm._v("warning")
                    ]),
                    _vm._t("default", [_vm._v(_vm._s(_vm.message))])
                  ],
                  2
                )
              ]
            }
          }
        ],
        null,
        true
      )
    },
    [_c("span", [_vm._v(_vm._s(_vm.message))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }