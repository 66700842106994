var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "editing-question-with-responses-dialog",
      attrs: { value: true, persistent: "", "max-width": "600" }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "mb-3 font-weight-bold" }, [
            _vm._v(" Editing question with responses ")
          ]),
          _c(
            "v-card-text",
            { staticClass: "editing-question-with-responses-dialog__content" },
            [
              _c("p", [
                _vm._v(
                  " Questions that already have responses can not have their Question Type or configuration (e.g. words) changed as this will cause the response data to no longer match the question being asked. "
                )
              ]),
              _c(
                "p",
                [
                  _vm._v(
                    " If you’d like to edit the Question Type or configuration, you must remove existing responses "
                  ),
                  _c("question-form-action-button", {
                    staticClass: "d-inline-block mx-1",
                    attrs: {
                      action: _vm.questionFormActions.CLEAR_RESPONSES,
                      tooltip: false
                    }
                  }),
                  _vm._v(" for the question and then edit it. ")
                ],
                1
              )
            ]
          ),
          _c(
            "v-card-actions",
            { staticClass: "pa-3" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }