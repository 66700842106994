var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-icon",
    {
      staticClass: "d-inline-block question-type-icon",
      attrs: { size: _vm.size, color: _vm.$attrs["color"] }
    },
    [_vm._v(" " + _vm._s(_vm.icon) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }