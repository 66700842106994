var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    _vm._l(_vm.iconOptions, function(iconOption, index) {
      return _c(
        "v-col",
        {
          key: index,
          staticClass: "d-flex justify-center pa-0",
          attrs: { cols: "12", md: "6" }
        },
        [
          _c(
            "v-card",
            { staticClass: "text-center h-100", attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pt-0" },
                [
                  _c(_vm.componentMap[iconOption], {
                    tag: "component",
                    staticClass: "icon",
                    class: _vm.componentMap[iconOption]
                  }),
                  _c(
                    "div",
                    { staticClass: "text-h6 font-weight-bold black--text" },
                    [_vm._v(" " + _vm._s(_vm.getNumber(iconOption)) + " ")]
                  ),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v(" " + _vm._s(_vm.getAverage(iconOption)) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }