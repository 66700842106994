var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { staticClass: "pa-0" }, [
        _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 " + _vm.maxBarWidth + " " + _vm.visualisationHeight
            }
          },
          [
            _vm._l(_vm.options, function(option, index) {
              return _c("rect", {
                key: "background_" + index,
                staticClass: "background",
                attrs: {
                  x: "0",
                  y: _vm.barYPosition(index),
                  width: _vm.maxBarWidth,
                  height: _vm.barHeight
                }
              })
            }),
            _vm._l(_vm.options, function(option, index) {
              return _c("rect", {
                key: "foreground_" + index,
                attrs: {
                  x: "0",
                  y: _vm.barYPosition(index),
                  width: _vm.barWidth(option.value),
                  height: _vm.barHeight,
                  fill: _vm.$vuetify.theme.currentTheme.primary
                }
              })
            }),
            _vm._l(_vm.options, function(option, index) {
              return _c(
                "text",
                {
                  key: "label_" + index,
                  staticClass: "text",
                  attrs: { x: _vm.textLeft, y: _vm.textYPosition(index) }
                },
                [_vm._v(" " + _vm._s(option.label) + " ")]
              )
            }),
            _vm._l(_vm.options, function(option, index) {
              return _c(
                "text",
                {
                  key: "option_" + index,
                  staticClass: "text",
                  attrs: {
                    x: _vm.textRight,
                    y: _vm.textYPosition(index),
                    "text-anchor": "end"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.percent(option.value)) + " ")]
              )
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }