var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-5 px-12 mb-4" },
        [
          _c(
            "v-row",
            {
              staticClass: "d-flex justify-space-between",
              attrs: { "no-gutters": "" }
            },
            [
              _c("div", { staticClass: "text-h5" }, [_vm._v("Members")]),
              _c(
                "v-btn",
                {
                  attrs: { fab: "", small: "", color: "primary" },
                  on: { click: _vm.requestAdd }
                },
                [_c("v-icon", [_vm._v("add")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-simple-table",
                { staticClass: "flex-grow-1" },
                [
                  [
                    _c("thead", [
                      _c(
                        "tr",
                        _vm._l(["Email", "Role", ""], function(header) {
                          return _c(
                            "th",
                            { key: header, staticClass: "text-left pl-0" },
                            [_vm._v(" " + _vm._s(header) + " ")]
                          )
                        }),
                        0
                      )
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.organisation.members, function(member) {
                        return _c("tr", { key: member.id }, [
                          _c("td", { staticClass: "pl-0" }, [
                            _vm._v(_vm._s(member.email))
                          ]),
                          _c("td", { staticClass: "pl-0" }, [
                            _vm._v(_vm._s(_vm.userRoles[member.userRole]))
                          ]),
                          _c(
                            "td",
                            {
                              staticClass:
                                "pl-0 d-flex justify-end align-center"
                            },
                            [
                              !member.hasLoggedIn
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        "x-small": "",
                                        "max-width": "34px",
                                        "min-height": "34px"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.requestResendInvite(member)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mail_outline")])],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    disabled: _vm.isCurrentUser(member),
                                    "x-small": "",
                                    "max-width": "34px",
                                    "min-height": "34px"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.requestEdit(member)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("edit")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: !_vm.canDelete(member),
                                    "x-small": "",
                                    "max-width": "34px",
                                    "min-height": "34px",
                                    color: "error"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.requestDelete(member)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("delete_outline")])],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("member-dialog", {
        attrs: {
          show: _vm.memberDialog,
          member: _vm.memberInDialog,
          organisation: _vm.organisation
        },
        on: {
          "update:show": function($event) {
            _vm.memberDialog = $event
          },
          "update:member": function($event) {
            _vm.memberInDialog = $event
          }
        }
      }),
      _vm.deleteRequested
        ? _c("action-modal", {
            attrs: {
              show: _vm.deleteRequested,
              title: _vm.deleteMemberDialogTitle
            },
            on: {
              cancel: function($event) {
                return _vm.cancelDelete()
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c("v-card-text", [
                        _vm._v(" If you remove "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.memberToDelete.email))
                        ]),
                        _vm._v(
                          " they will no longer be able to log in and access their surveys, but any surveys they have created will not be deleted. "
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.confirmDelete()
                            }
                          }
                        },
                        [_vm._v(" Remove ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              295308090
            )
          })
        : _vm._e(),
      _vm.resendInviteRequested
        ? _c("action-modal", {
            attrs: { show: _vm.resendInviteRequested, title: "Re-Invite User" },
            on: {
              cancel: function($event) {
                return _vm.cancelResendInvite()
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c("v-card-text", [
                        _vm._v(" This will resend the invitation email to "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.memberToResendInvite.email) + ".")
                        ])
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.confirmResendInvite()
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v("mail_outline")
                          ]),
                          _vm._v(" Send ")
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3813125979
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }