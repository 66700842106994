var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c(
        _vm.componentType,
        _vm._b(
          {
            tag: "component",
            attrs: {
              value: _vm.value,
              items: _vm.languages,
              multiple: _vm.multiple,
              "item-text": "name",
              "item-value": "code",
              chips: ""
            },
            on: { change: _vm.handleChange },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.templateConfigs, function(config, i) {
                  return {
                    key: config.slot,
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.name
                          ? _c(config.component, {
                              key: item.name || i,
                              tag: "component",
                              attrs: { language: item }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                })
              ],
              null,
              true
            )
          },
          "component",
          _vm.$attrs,
          false
        )
      ),
      _vm.showHelper
        ? _c(
            "div",
            [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "", "max-width": "350" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g({ staticClass: "ml-2 mt-6" }, on),
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v("help")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    417247043
                  )
                },
                [
                  _c(
                    "span",
                    [
                      _vm._t("tooltip", [
                        _vm._v(" This is the default tooltip ")
                      ])
                    ],
                    2
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }