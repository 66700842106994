var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-card",
            { staticClass: "text-center", attrs: { flat: "", height: "100%" } },
            [
              _c("v-card-text", { staticClass: "pt-0" }, [
                _c(
                  "svg",
                  {
                    style: { width: _vm.width, height: _vm.height },
                    attrs: {
                      viewBox:
                        "0 0 " +
                        _vm.outerCircleRadius * 2 +
                        " " +
                        _vm.outerCircleRadius * 2
                    }
                  },
                  [
                    _c("circle", {
                      style: {
                        fill: "none",
                        stroke: _vm.color,
                        strokeWidth: 5
                      },
                      attrs: { cx: _vm.x1, cy: _vm.y1, r: "47%" }
                    }),
                    _c("circle", {
                      style: {
                        fill: _vm.color,
                        stroke: _vm.color,
                        strokeWidth: 2
                      },
                      attrs: { cx: _vm.x1, cy: _vm.y1, r: "2%" }
                    }),
                    _c("line", {
                      style: { stroke: _vm.color, strokeWidth: 4 },
                      attrs: {
                        x1: _vm.x1,
                        y1: _vm.y1,
                        x2: _vm.minutesX2,
                        y2: _vm.minutesY2
                      }
                    }),
                    _c("circle", {
                      style: {
                        fill: _vm.color,
                        stroke: _vm.color,
                        strokeWidth: 0.05
                      },
                      attrs: { cx: _vm.minutesX2, cy: _vm.minutesY2, r: "2%" }
                    }),
                    _vm.hours > 0
                      ? [
                          _c("line", {
                            style: { stroke: _vm.color, strokeWidth: 5 },
                            attrs: {
                              x1: _vm.x1,
                              y1: _vm.y1,
                              x2: _vm.hoursX2,
                              y2: _vm.hoursY2
                            }
                          }),
                          _c("circle", {
                            style: {
                              fill: _vm.color,
                              stroke: _vm.color,
                              strokeWidth: 1
                            },
                            attrs: { cx: _vm.hoursX2, cy: _vm.hoursY2, r: "2%" }
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _c("v-card-text", { staticClass: "pt-0" }, [
                _c(
                  "div",
                  { staticClass: "text-h6 font-weight-bold black--text" },
                  [_vm._v(" " + _vm._s(_vm.time) + " ")]
                ),
                _c("div", { staticClass: "text-subtitle-1" }, [
                  _vm._v(" on average ")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }