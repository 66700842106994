var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "pa-6", attrs: { cols: "8" } },
        [
          _c("emotion-wheel", {
            attrs: {
              slices: _vm.chartSlices,
              cutout: "",
              filled: "",
              "show-labels": ""
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "d-flex flex-column justify-center pa-0",
          attrs: { cols: "4" }
        },
        [
          _c("emotion-wheel-legend", {
            attrs: {
              items: _vm.legendItems,
              "display-language": _vm.displayLanguage
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }