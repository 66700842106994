var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.show, width: "50%", persistent: "" },
      on: {
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            $event.stopPropagation()
            return _vm.closeDialog($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft"
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 0) {
              return null
            }
            $event.stopPropagation()
            return _vm.previousQuestion($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "right", 39, $event.key, [
                "Right",
                "ArrowRight"
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 2) {
              return null
            }
            $event.stopPropagation()
            return _vm.nextQuestion($event)
          }
        ]
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "text-h5 grey lighten-2",
              attrs: { "primary-title": "" }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.selectedSurvey.title) + " Survey Preview "
              )
            ]
          ),
          _c(
            "v-card-text",
            { staticClass: "pb-0 preview-card" },
            [
              _c(
                "v-card-title",
                [
                  _c("v-spacer"),
                  _vm._v(" " + _vm._s(_vm.questionText) + " "),
                  _c("v-spacer")
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          class: {
                            "disabled-button": !_vm.hasPreviousQuestion,
                            "grey--text": !_vm.hasPreviousQuestion
                          },
                          attrs: {
                            color: "grey lighten-2 black--text",
                            dark: "",
                            small: "",
                            fab: ""
                          },
                          on: { click: _vm.previousQuestion }
                        },
                        [_c("v-icon", [_vm._v("arrow_back")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pt-0", attrs: { cols: "10" } },
                    _vm._l(_vm.questionsList, function(item, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "question-preview" },
                        [
                          _c(
                            "v-fade-transition",
                            { attrs: { mode: "out-in", "hide-on-leave": "" } },
                            [
                              i === _vm.selectedIndex
                                ? _c("question-preview-video", {
                                    attrs: {
                                      "question-type": item.config.questionType
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          class: {
                            "disabled-button": !_vm.hasNextQuestion,
                            "grey--text": !_vm.hasNextQuestion
                          },
                          attrs: {
                            color: "grey lighten-2 black--text",
                            dark: "",
                            small: "",
                            fab: ""
                          },
                          on: { click: _vm.nextQuestion }
                        },
                        [_c("v-icon", [_vm._v("arrow_forward")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("span", { staticClass: "current-question-text text-center" }, [
                _vm._v(
                  _vm._s(_vm.currentQuestionDisplayNumber) +
                    "/" +
                    _vm._s(_vm.amountOfQuestions)
                )
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.closeDialog }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }