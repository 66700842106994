var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showDialog, persistent: "", "max-width": "560" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
          _vm.show
            ? _c("organisation-form", {
                attrs: { organisation: _vm.organisation },
                on: {
                  close: function($event) {
                    return _vm.$emit("update:show", false)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }