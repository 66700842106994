var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("page-header-bar", [
        _c("div", { staticClass: "d-flex p-relative" }, [
          _c(
            "div",
            { staticClass: "flex-grow-1 d-flex justify-space-between" },
            [
              _c("h5", { staticClass: "white--text text-h5" }, [
                _vm._v(" Surveys ")
              ]),
              _c(
                "div",
                { staticClass: "justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "accent" },
                      on: { click: _vm.showSurveyDialog }
                    },
                    [_vm._v(" Create Survey ")]
                  )
                ],
                1
              )
            ]
          )
        ])
      ]),
      _c(
        "v-container",
        { staticClass: "pa-8" },
        [
          _c("surveys-view-controller", {
            ref: "surveysViewController",
            attrs: { "survey-filter-text": _vm.surveyFilterText }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }