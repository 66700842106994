<template>
  <div>
    <slider-svg
      class="mx-12"
      :theme="theme"
      :width="responsiveWidth"
      :slider-value="questionStat.average"
      :middle-tick="!!questionStat.middleWord"
    />
    <div class="d-flex justify-space-between">
      <div
        v-for="(sliderPoint, index) in sliderPoints"
        :key="index"
        class="text-body-1 text-center font-weight-bold label"
      >
        <localised-text
          :language="displayLanguage"
          :string="sliderPoint.text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LocalisedText from '@/components/Common/LocalisedText';
import SliderSvg from '@/components/Visualisations/SliderSvg';
import colors from 'vuetify/lib/util/colors';

export default {
  components: {
    LocalisedText,
    SliderSvg
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    handlePosition() {
      return {
        left: `${this.questionAverage}%`
      };
    },
    sliderPoints() {
      const options = [
        {
          position: 'left',
          text: this.questionStat.leftWord
        },
        {
          position: 'right',
          text: this.questionStat.rightWord
        }
      ];

      if (
        this.questionStat.middleWord &&
        this.questionStat.middleWord.languageMap[this.displayLanguage]
      ) {
        options.splice(1, 0, {
          position: 'middle',
          text: this.questionStat.middleWord
        });
      }

      return options;
    },
    questionAverage() {
      return (this.questionStat.average * 100).toFixed(0);
    },
    responsiveWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 200;
        case 'sm':
          return 200;
        case 'md':
          return 300;
        case 'lg':
          return 400;
        case 'xl':
          return 600;
        default:
          return 800;
      }
    },
    theme() {
      return {
        slider: {
          barColour: colors.grey.base,
          tickColour: colors.grey.base,
          gradient: {
            start: this.$vuetify.theme.defaults.light.primary,
            end: this.$vuetify.theme.defaults.light.primary
          }
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  min-width: 100px;
}
</style>
