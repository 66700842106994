var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "100vh" },
      on: { "click:outside": _vm.cancelDialog },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "text-capitalize justify-center",
              attrs: { "fill-width": "" }
            },
            [_vm._v(" " + _vm._s(_vm.getQuestionTitle()) + " ")]
          ),
          _c("v-divider"),
          _c(
            "v-list",
            { staticClass: "word-responses-list" },
            [
              _c(
                "v-list-item-group",
                { staticClass: "mt-2 mb-2 ml-4 mr-4" },
                _vm._l(_vm.questionStat.responsesWithTimes, function(
                  response,
                  index
                ) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      staticClass: "text-response-background",
                      attrs: {
                        inactive: "",
                        selectable: _vm.interactionEnabled,
                        ripple: _vm.interactionEnabled,
                        "two-line": ""
                      }
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatDateTimeString(
                                            response.createdAt,
                                            "ddd D MMM YYYY"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "v-list-item-subtitle",
                                    {
                                      staticClass: "wrap-text mt-2 grey--text"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDateTimeString(
                                              response.createdAt,
                                              "HH:mm"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-col", [
                                _vm._v(" " + _vm._s(response.value) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c(
                "v-container",
                { staticClass: "d-flex justify-end mr-4 mb-n2" },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }