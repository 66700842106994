var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("language-avatar", { attrs: { language: _vm.language } }),
      _c("span", { staticClass: "language ml-2" }, [
        _vm._v(_vm._s(_vm.language.name))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }