var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-5 px-12 mb-6" },
        [
          _c(
            "v-row",
            {
              staticClass: "d-flex justify-space-between",
              attrs: { "no-gutters": "" }
            },
            [
              _c("div", { staticClass: "text-h5" }, [
                _vm._v("Organisation Details")
              ]),
              _c(
                "v-btn",
                {
                  attrs: {
                    "x-small": "",
                    color: "grey lighten-5",
                    "max-width": "34px",
                    "min-height": "34px"
                  },
                  on: { click: _vm.requestEdit }
                },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("edit")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-4", attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-subtitle-1 grey--text text--darken-1 mb-1 mt-2"
                  },
                  [_vm._v(" Organisation Name ")]
                ),
                _c("p", { staticClass: "black--text pb-0 mb-0" }, [
                  _vm._v(" " + _vm._s(_vm.organisation.name) + " ")
                ])
              ]),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-subtitle-1 grey--text text--darken-1 mb-1 mt-2"
                  },
                  [_vm._v(" Address ")]
                ),
                _vm.organisation.address
                  ? _c("p", { staticClass: "black--text pb-0 mb-0" }, [
                      _vm._v(" " + _vm._s(_vm.addressOrPlaceholder) + " ")
                    ])
                  : _c(
                      "p",
                      { staticClass: "grey--text text--lighten-1 pb-0 mb-0" },
                      [_vm._v(" (Address not set) ")]
                    )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-4", attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-subtitle-1 grey--text text--darken-1 mb-1 mt-2"
                  },
                  [_vm._v(" Primary Language ")]
                ),
                _c(
                  "p",
                  { staticClass: "black--text pb-0 mb-0" },
                  [
                    _c("language-chip", {
                      attrs: { language: _vm.primaryLanguage }
                    })
                  ],
                  1
                )
              ]),
              _vm.hasSecondaryLanguages
                ? _c("v-col", { attrs: { cols: "8" } }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-subtitle-1 grey--text text--darken-1 mb-1 mt-2"
                      },
                      [_vm._v(" Secondary Languages ")]
                    ),
                    _c(
                      "div",
                      _vm._l(_vm.secondaryLanguages, function(
                        secondaryLanguage,
                        i
                      ) {
                        return _c("language-chip", {
                          key: i,
                          staticClass: "mr-2",
                          attrs: { language: secondaryLanguage }
                        })
                      }),
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "flex-column mb-4", attrs: { "no-gutters": "" } },
            [
              _c(
                "p",
                {
                  staticClass:
                    "text-subtitle-1 grey--text text--darken-1 mb-1 mt-2"
                },
                [_vm._v(" iPad Username ")]
              ),
              _c("p", { staticClass: "black--text pb-0 mb-0" }, [
                _vm._v(" " + _vm._s(_vm.organisation.ipadUsername) + " ")
              ])
            ]
          )
        ],
        1
      ),
      _c("organisation-dialog", {
        attrs: { organisation: _vm.organisation, show: _vm.organisationDialog },
        on: {
          "update:show": function($event) {
            _vm.organisationDialog = $event
          }
        }
      }),
      _c("member-table", { attrs: { organisation: _vm.organisation } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }