var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "justify-center" },
        [
          _c("v-img", {
            attrs: { src: _vm.getSpikyBallImgUrl, "max-width": "200px" }
          })
        ],
        1
      ),
      _c(
        "v-card-text",
        {
          staticClass: "text-h6 font-weight-bold black--text text-center pt-0"
        },
        [_vm._v(" " + _vm._s(_vm.emotion) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }