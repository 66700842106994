var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.width, persistent: "" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          $event.stopPropagation()
          return _vm.$emit("cancel")
        }
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-capitalize" }, [
            _vm._v(" " + _vm._s(_vm.title) + " ")
          ]),
          _vm._t("body", [
            _c("v-card-text", [
              _vm._v(" This is the default content for the body slot ")
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-container",
                { staticClass: "d-flex justify-end" },
                [
                  _vm.cancel
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          on: {
                            click: function($event) {
                              return _vm.$emit("cancel")
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    : _vm._e(),
                  _vm._t("actions", [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("confirm")
                          }
                        }
                      },
                      [_vm._v(" Confirm ")]
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }