var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("question-list", {
    staticClass: "mt-4",
    attrs: { "target-language": _vm.survey.primaryLanguage }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }