import { render, staticRenderFns } from "./SurveyPage.vue?vue&type=template&id=77fdda2d&scoped=true&"
import script from "./SurveyPage.vue?vue&type=script&lang=js&"
export * from "./SurveyPage.vue?vue&type=script&lang=js&"
import style0 from "./SurveyPage.vue?vue&type=style&index=0&id=77fdda2d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77fdda2d",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VContainer,VIcon,VTab,VTabItem,VTabs,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/Users/allanjones/Code/deakin/muse/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77fdda2d')) {
      api.createRecord('77fdda2d', component.options)
    } else {
      api.reload('77fdda2d', component.options)
    }
    module.hot.accept("./SurveyPage.vue?vue&type=template&id=77fdda2d&scoped=true&", function () {
      api.rerender('77fdda2d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/SurveyPage.vue"
export default component.exports