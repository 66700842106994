<template>
  <v-row>
    <v-col cols="2" class="pt-0">
      <v-text-field
        v-model="configModel.left.languageMap[primaryLanguage]"
        class="text-body-2"
        label="Left Word"
        @change="configUpdated()"
      />
    </v-col>
    <v-col cols="2" class="pt-0">
      <v-text-field
        v-model="configModel.middle.languageMap[primaryLanguage]"
        class="text-body-2"
        label="Middle Word"
        @change="configUpdated()"
      />
    </v-col>
    <v-col cols="2" class="pt-0">
      <v-text-field
        v-model="configModel.right.languageMap[primaryLanguage]"
        class="text-body-2"
        label="Right Word"
        @change="configUpdated()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SliderConfig',
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return { configModel: {} };
  },
  computed: {
    ...mapGetters('survey', ['primaryLanguage'])
  },
  watch: {
    value() {
      this.configModel = Object.assign(
        {},
        this.configModel,
        this.value
      );
    }
  },
  created() {
    this.configModel = Object.assign(
      {},
      this.configModel,
      this.value
    );
  },
  methods: {
    configUpdated() {
      this.$emit('input', this.configModel);
      this.$emit('change', this.configModel);
    }
  }
};
</script>
