var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasIssues
    ? _c(
        "div",
        [
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "1" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { size: "24", color: "error" }
                    },
                    [_vm._v(" error ")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                _vm._l(_vm.surveyIssues, function(issue, i) {
                  return _c("p", { key: i, staticClass: "issue my-0" }, [
                    _vm._v(" " + _vm._s(issue) + " ")
                  ])
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    : !_vm.isPublished && !_vm.hasIssues
    ? _c(
        "div",
        [
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "1" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { size: "24", color: "success" }
                    },
                    [_vm._v(" check_circle ")]
                  )
                ],
                1
              ),
              _c("v-col", [
                _c("p", { staticClass: "issue my-0" }, [
                  _vm._v("Ready to publish")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }