var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.visibleStatuses, function(status) {
      return _c(
        "v-btn",
        {
          key: status.type,
          staticClass: "ml-2",
          class: _vm.buttonClass(status.type),
          attrs: { rounded: "" },
          on: {
            click: function($event) {
              return _vm.toggleStatusVisibility(status.type)
            }
          }
        },
        [
          _vm.statusCounters[status.type] > 0
            ? _c(
                "v-badge",
                {
                  attrs: {
                    inline: "",
                    content: _vm.statusCounters[status.type],
                    color: _vm.badgeColor(status.type)
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                    _vm._v(_vm._s(status.icon))
                  ]),
                  _vm._v(" " + _vm._s(status.label) + " ")
                ],
                1
              )
            : _c(
                "span",
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(_vm._s(status.icon))
                  ]),
                  _vm._v(" " + _vm._s(status.label) + " ")
                ],
                1
              )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }