var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "p absolute" },
    [
      _c(
        "v-tabs",
        { staticClass: "pa-5", attrs: { grow: "" } },
        [
          _c(
            "v-tab",
            { attrs: { disabled: "" } },
            [
              _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v("format_list_numbered")
              ]),
              _vm._v("Questions ")
            ],
            1
          ),
          _c(
            "v-tab",
            { attrs: { disabled: "" } },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("bar_chart")]),
              _vm._v("Dashboard ")
            ],
            1
          ),
          _c(
            "v-tab",
            { attrs: { disabled: "" } },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("save_alt")]),
              _vm._v("Export Data ")
            ],
            1
          )
        ],
        1
      ),
      _vm._l(_vm.loaderCount, function(loader) {
        return _c(
          "div",
          { key: loader, staticClass: "pb-4 pl-5" },
          [_c("question-loader")],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }